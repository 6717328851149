/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";

import { useParams } from "react-router-dom";

import { useLocalization } from "@progress/kendo-react-intl";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Error } from "@progress/kendo-react-labels";
import {
  emailValidator,
  requiredValidator,
  passwordMatchValidator,
  partsCodeValidator,
  address1Validator,
  cityValidator,
  stateValidator,
  zipValidator,
  phoneValidator,
  newPasswordValidator,
  dealerCodeValidator,
  dollarValidator,
  positiveNumberValidator,
} from "../validators";
import raw_timezones from "src/stores/timezones.json";

import { AppContext } from "../AppContext";
import { useServices } from "src/services";
import { TextArea, Input } from "@progress/kendo-react-inputs";
import { DatePicker } from 'src/components/Form/DatePicker';
import { TimePicker } from 'src/components/Form/TimePicker';
import { NumericTextBox } from 'src/components/Form/NumericTextBox';
import { MaskedTextBox } from "src/components/Form/MaskedTextBox";
import { useHistory } from "react-router";
import moment from "moment";
import capitalize from "src/utils/capitalize";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnProgressEvent,
  UploadOnRemoveEvent,
  UploadOnStatusChangeEvent,
} from "@progress/kendo-react-upload";
import {
  CompositeFilterDescriptor,
  filterBy,
  FilterDescriptor,
} from "@progress/kendo-data-query";
import { DropDownList } from "src/components/Form/DropDownList";
import { DropDownListFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { Upload } from "src/components/Form/Upload";
import { Dealer, D4URxEvent, UserDetails } from "src/utils/classes";
import { useStores } from "src/stores";

type EventScreenParams = {
  event_id: string;
};

const dealerTypes = [
  { value: "F", text: "Ford" },
  { value: "FL", text: "Ford/Lincoln" },
];

const dealerRegions = [
  { value: "CE", text: "Central Market Area" },
  { value: "EMA", text: "East Market Area" },
  { value: "GL", text: "Great Lakes Market Area" },
  { value: "SE", text: "Southeast Market Area" },
  { value: "W", text: "West Market Area" },
];

const fontBold = { fontWeight: 700 }
const fontSemiBold = { fontWeight: 600 }

const EventDetailScreen = () => {
  const { ...formValues } = React.useContext(AppContext);
  const localizationService = useLocalization();
  const formRef = React.useRef(null);

  const { d4ur } = useServices();
  const { login } = useStores();
  let history = useHistory();
  const { event_id } = useParams<EventScreenParams>();

  const siteDomain: string =
    process.env.REACT_APP_ENV === "staging"
      ? "staging.d4urs.com"
      : "d4urs.com";

  const [dialogDisplayed, setDialogDisplayed] = useState<string>("none");
  const [selectedEmailType, setEmailType] = useState<string>("");
  const [formDisplayed, setFormDisplayed] = useState<string>("none");
  const [formError, setFormError] = useState<string>();

  const [planners, setPlanners] = useState<UserDetails[]>([]);

  const [thisEvent, setThisEvent] = useState<D4URxEvent>();
  const [customData, setCustomData] = useState<any>({});
  const [addressVerified, toggleAddressVerified] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showRRDMessage, setShowRRDMessage] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [displayOtherAddressForm, setDisplayOtherAddressForm] = useState<boolean>(false);
  const [secondaryOrgDeleted, deleteSecondaryOrg] = useState<boolean>(false);
  const [shareChangesVisible, setShareChangesVisible] = useState<boolean>(false);

  interface IMagic {
    url: string;
    token: string;
  }
  const [magicLink, setMagicLink] = useState<IMagic>();
  const [dealer, setDealer] = useState<Dealer>();
  const [charity, setCharity] = useState<Charity>();
  const [registrants, setRegistrants] = useState<Registrants>();
  // const [assignedPlanner, setAssignedPlanner] = useState<UserDetails>();
  const [timeZones, setTimeZones] = useState<string[]>();

  const [files, setFiles] = React.useState<Array<UploadFileInfo>>([]);
  const onAdd = (event: UploadOnAddEvent) => {
    console.log("onAdd: ", event.affectedFiles);

    setFiles(event.newState);
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    console.log("onRemove: ", event.affectedFiles);

    setFiles(event.newState);
  };

  const onProgress = (event: UploadOnProgressEvent) => {
    console.log("onProgress: ", event.affectedFiles);

    setFiles(event.newState);
  };

  const onStatusChange = (event: UploadOnStatusChangeEvent) => {
    console.log("onStatusChange: ", event.affectedFiles);

    setFiles(event.newState);
  };

  const doMagicLink = () => {
    console.log("magicLink", magicLink);
    const { url, token } = magicLink!;
    window.open(`${url}/auto_login/${token}`, "_blank");
  };

  const getRequestData = () => {
    setFormError("");
    setTimeZones(raw_timezones);
    d4ur.getPlanners().then((plannersResult) => {
      // const foundPlanners = (plannersResult as D4URxFetchResult).result as UserDetails[];
      setPlanners((plannersResult as D4URxFetchResult).result as UserDetails[]);
      d4ur.getEventToken(event_id).then((tokenResult) => {
        const { token, url } = (tokenResult as D4URxFetchResult).result;

        setMagicLink({
          token,
          url,
        });
      });

      d4ur.getEvent(event_id).then((ret) => {
        console.log("ret ****", ret);

        const { event, dealer, charity, registrants } = (ret as D4URxFetchResult).result;

        // const { assigned_planner } = JSON.parse(event?.eligibility_custom_data || "{}");

        charity.address_2 = charity.address_2 || "";

        // setAssignedPlanner(
        //   foundPlanners?.find((x) => x.user_id === assigned_planner)
        // );
        // console.log("event!!!", event.status);

        setThisEvent(new D4URxEvent(event));
        setCustomData(JSON.parse(event.event_custom_data || "{}"));
        console.log("customData", JSON.parse(event.event_custom_data || "{}"));
        setDealer(new Dealer(dealer));
        setCharity(charity as Charity);
        setRegistrants(registrants as Registrants)
      });
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getRequestData, []);

  const ref: any = React.useRef(null);
  const attendanceRef: any = React.useRef(null);
  const dateRef: any = React.useRef(null);
  const startTimeRef: any = React.useRef(null);
  const endTimeRef: any = React.useRef(null);

  const handleOnWheel = (event: any) => {
    event.stopPropagation();
  };

  React.useEffect(() => {
    ref?.current?.element.addEventListener('wheel', handleOnWheel);
    attendanceRef?.current?.element.addEventListener('wheel', handleOnWheel);
    dateRef?.current?.element.addEventListener('wheel', handleOnWheel);
    startTimeRef?.current?.element.addEventListener('wheel', handleOnWheel);
    endTimeRef?.current?.element.addEventListener('wheel', handleOnWheel);

    return () => {
      ref?.current?.element.removeEventListener('wheel', handleOnWheel);
      attendanceRef?.current?.element.removeEventListener('wheel', handleOnWheel);
      dateRef?.current?.element.removeEventListener('wheel', handleOnWheel);
      startTimeRef?.current?.element.removeEventListener('wheel', handleOnWheel);
      endTimeRef?.current?.element.removeEventListener('wheel', handleOnWheel);
    };
  });

  const onSubmitDealer = React.useCallback(
    async (dataItem) => {
      setFormDisplayed("none");

      if (!dataItem.confirm_password) {
        delete dataItem.password;
      }

      const call: any = await d4ur.updateDealer(dataItem);
      if (call.result?.errors) {
        console.log("[EventDetailScreen]", "Update Dealer Error");
        setFormError(call.result.errors.join("\n"));
      } else {
        console.log("[EventDetailScreen]", "dealer updated");
        history.push('/events');
      }
    },
    [d4ur, history]
  );

  const onSubmitCharity = React.useCallback(
    async (dataItem) => {
      setFormDisplayed("none");

      const call: any = await d4ur.updateCharity(dataItem);
      if (call.result.errors) {
        console.log("[EventDetailScreen]", "Update Charity Error");
        setFormError(call.result.errors.join("\n"));
      } else {
        console.log("[EventDetailScreen]", "charity updated");
        history.push('/events');
      }
    },
    [d4ur, history]
  );

  const onSubmitPlanner = React.useCallback(
    async (dataItem) => {
      console.log("dataItem!!!!!!!!", dataItem);
      d4ur
        .setEligibleEventRequest(
          thisEvent!.event_request_id!,
          dataItem.assigned_planner.user_id
        )
        .then((res) => {
          setDialogDisplayed("none");
          history.push('/events');
        });
    },
    [d4ur, history, thisEvent]
  );

  const onSubmitUpdateEvent = React.useCallback(
    async (dataItem) => {
      if (!displayOtherAddressForm && dataItem.event_location_type && thisEvent?.event_location_type !== 'Other') {
        dataItem.address = '';
        dataItem.city = '';
        dataItem.state = '';
        dataItem.zip_code = '';
      }
      if (!dataItem.event_location_type) {
        dataItem.event_location_type = thisEvent?.event_location_type;
      }
      if (secondaryOrgDeleted) {
        dataItem.secondary_charity = null;
      }

      // start and end are the same, need to update both
      dataItem.end_date = dataItem.start_date;

      try {
        d4ur.updateEvent(dataItem)
        // setShareChangesVisible(true);
      } catch (e) {
        console.log("[EventDetailScreen]", "Update event Error", e);
        setFormError('There was an error updating this event, please contact the development team.');
      }
      setFormDisplayed("none");
      history.push('/events');
    },
    [d4ur, history, displayOtherAddressForm, secondaryOrgDeleted, thisEvent]
  );

  const onSubmitSendEmails = React.useCallback(
    async (dataItem) => {
      setIsLoading(true);
      setSuccessMessage('');
      setErrorMessage('');
      // todo: type ret object
      d4ur.triggerEmail(dataItem.email_type.id, thisEvent?.event_id, dataItem.send_to).then((ret: any) => {
        // console.log('triggerEmail event ret:', ret)
        if (ret.response.status === 200) {
          formRef.current?.resetForm();
          setIsLoading(false);
          setEmailType('');
          setSuccessMessage('Successfully sent emails!')
        } else {
          setErrorMessage(ret.result.message)
          setIsLoading(false);
        }
        return;
      })
        .catch(e => console.log('trigger emaik error:', e))
    },
    [d4ur, history, thisEvent]
  );

  const generateLastSentText = (emailType: string) => {
    const getLastSentAt = (emailsSent: Array<emailsSent>) => {
      if (!emailsSent) return 'Not Yet Sent';
      const parseDate = (email: emailsSent) => new Date(email.sent_at);
      emailsSent.sort((a, b) => parseDate(b).getTime() - parseDate(a).getTime());
      const mostRecentEmail = emailsSent[0];
      return `${moment(mostRecentEmail.sent_at).tz('America/New_York').format('MMMM Do, h:mm a')} (EST) to ${mostRecentEmail.sent_to.length > 1 ? mostRecentEmail.sent_to.join(', ') : mostRecentEmail.sent_to}`;
    }
    if (!emailType) return;
    if (emailType.id === 'pre_event') {
      return (
        <p className={"send-emails-subtext"}>This email was last sent at: {getLastSentAt(thisEvent?.formatted_custom_data?.training_reminder_sent)}</p>
      );
    } else if (emailType.id === 'kit_delivery') {
      return (
        <p className={"send-emails-subtext"}>This email was last sent at: {moment(thisEvent?.formatted_custom_data?.kit_delivery_email_sent).tz('America/New_York').format('MMMM Do, h:mm a') || 'Not Yet Sent'}</p>
      );
    } else if (emailType.id === 'event_approved') {
      return (
        <p className={"send-emails-subtext"}>This email was last sent at: {getLastSentAt(thisEvent?.formatted_custom_data?.event_approved_resend)}</p>
      );
    }
  }

  const rejectEligibility = () => {
    d4ur.setIneligibleEventRequest(thisEvent!.event_request_id!).then((res) => {
      setDialogDisplayed("none");
      history.push('/events');
    });
  };

  const filterTimeZones = (
    filter: FilterDescriptor | CompositeFilterDescriptor
  ) => {
    return filterBy(raw_timezones, filter);
  };

  const filterTZ = (event: DropDownListFilterChangeEvent) => {
    setTimeZones(filterTimeZones(event.filter));
  };

  const formatRange = (start?: Date, end?: Date) => {
    let range = "";
    if (start) {
      range += moment(start).format("h:mm a");
    }
    if (start && end) {
      range += " - ";
    }
    if (end) {
      range += moment(end).format("h:mm a");
    }

    return range;
  };

  const { file_id } = JSON.parse(
    thisEvent?.event_custom_data || "{}"
  );

  const onSubmitPlan = React.useCallback(
    async (dataItem) => {
      console.log("dataItem!!!!!!!!", dataItem);
      setFormDisplayed("none");

      // format dates properly
      if (dataItem.start_date) {
        dataItem.start_date = moment(dataItem.start_date).format("YYYY-MM-DD");
      }
      if (dataItem.start_time) {
        dataItem.start_time = moment(dataItem.start_time).format("HH:mm:00");
      }
      if (dataItem.end_time) {
        dataItem.end_time = moment(dataItem.end_time).format("HH:mm:00");
      }

      let customData = JSON.parse(thisEvent!.custom_data || "{}");
      customData = {
        ...customData,
        requested_month: dataItem.requested_month,
        requested_date: dataItem.requested_date,
      };

      dataItem.custom_data = JSON.stringify(customData);

      let eventCustomData = JSON.parse(thisEvent!.event_custom_data || "{}");
      eventCustomData = {
        ...eventCustomData,
        centuryParty: dataItem.event_type,
      };

      dataItem.event_custom_data = JSON.stringify(eventCustomData);

      const [selectedFile] = files;
      const rawFile =
        selectedFile && selectedFile.getRawFile && selectedFile.getRawFile();
      // if (rawFile) {
      //     dataItem.event_plan_file = rawFile;
      // }

      const call: any = await d4ur.saveEventPlan(
        new D4URxEvent(dataItem),
        rawFile
      );
      if (call.result.errors) {
        console.log("[EventDetailScreen]", "Update Event Request Error");
        setFormError(call.result.errors.join("\n"));
      } else {
        console.log("[EventDetailScreen]", "Event Request updated");
        history.push('/events');
      }
    },
    [d4ur, files, history, thisEvent]
  );

  const onDenyPlan = React.useCallback(
    async (dataItem) => {
      console.log("dataItem!!!!!!!!", dataItem);
      d4ur
        .denyPlan(thisEvent!.event_request_id!, dataItem.deny_message)
        .then((res) => {
          setDialogDisplayed("none");
          history.push("/events");
        });
    },
    [d4ur, history, thisEvent]
  );

  const onSubmitFinalApproval = React.useCallback(
    async (dataItem) => {
      const call: any = await d4ur.submitFinalApproval(
        thisEvent?.event_request_id
      );
      if (call.result.errors) {
        console.log("[EventDetailScreen]", "Event Approved Error");
        setFormError(call.result.errors.join("\n"));
      } else {
        console.log("[EventDetailScreen]", "Event Approved");
        history.push("/events");
      }
    },
    [d4ur, history, thisEvent]
  );

  const onSubmitReimbursementApproval = React.useCallback(
    async (dataItem) => {
      console.log("DATA ITEM SUBMIT", dataItem);
      if (!dataItem.reimbursement_amount || dataItem.reimbursement_amount <= 0) {
        setFormError("Reimbursement amount is required.");
        return;
      }
      setFormError("");
      const call: any = await d4ur.submitReimbursementApproval(
        thisEvent?.event_id,
        dataItem.reimbursement_amount,
      );
      if (call.result.errors) {
        console.log("[EventDetailScreen]", "Submit Reimbursement Error");
        setFormError(call.result.errors.join("\n"));
      }
      if (
        Object.keys(call.result).includes("verified") &&
        call.result.verified === false
      ) {
        console.log("success but not verified!!");
        toggleAddressVerified(false);
        // display message and display button to force through
      } else {
        toggleAddressVerified(true);
        console.log("[EventDetailScreen]", "Reimbursement Approved");
        history.push("/events");
      }
    },
    [d4ur, history, thisEvent]
  );

  const onSubmitReimbursementApprovalForceAddress = React.useCallback(
    async (dataItem) => {
      const call: any = await d4ur.submitForcedReimbursementApproval(
        thisEvent?.event_id,
        dataItem.reimbursement_amount,
      );
      console.log("DONATION FORCED CALL", call);

      if (call.result.errors) {
        console.log("[EventDetailScreen]", "Reimbursement Approved Error");
        setFormError(call.result.errors.join("\n"));
      } else {
        toggleAddressVerified(true);
        console.log("[EventDetailScreen]", "Reimbursement Approved");
        history.push("/events");
      }
    },
    [d4ur, history, thisEvent]
  );

  const onSubmitDonationApproval = React.useCallback(
    async (dataItem) => {

      const call: any = await d4ur.submitDonationApproval(
        thisEvent?.event_id,
        dataItem.event_summary,
        dataItem.check_amount,
        dataItem.valid_post_count,
        dataItem.bonus_valid_count,
      );

      if (call.result.errors) {
        console.log("[EventDetailScreen]", "Donation Approved Error");
        setFormError(call.result.errors.join("\n"));
      }
      if (
        Object.keys(call.result).includes("verified") &&
        call.result.verified === false
      ) {
        console.log("success but not verified!!");
        toggleAddressVerified(false);
        // display message and display button to force through
      } else {
        toggleAddressVerified(true);
        console.log("[EventDetailScreen]", "Donation Approved");
        history.push("/events");
      }
    },
    [d4ur, history, thisEvent]
  );

  const onSubmitDonationApprovalForceAddress = React.useCallback(
    async (dataItem) => {
      console.log('dataItem', dataItem);
      const call: any = await d4ur.submitForcedDonationApproval(
        thisEvent?.event_id,
        dataItem.event_summary,
        dataItem.check_amount,
        dataItem.valid_post_count,
        dataItem.bonus_valid_count,
      );
      console.log("DONATION FORCED CALL", call);

      if (call.result.errors) {
        console.log("[EventDetailScreen]", "Donation Approved Error");
        setFormError(call.result.errors.join("\n"));
      } else {
        toggleAddressVerified(true);
        console.log("[EventDetailScreen]", "Donation Approved");
        history.push("/events");
      }
    },
    [d4ur, history, thisEvent]
  );

  const eventTypes = [
    { text: "No test-drive or walkaround", value: "basic" },
    { text: "Test-Drive", value: "test_drive" },
    { text: "Walkaround", value: "walkaround" },
  ];

  const eventPortalLink = `https://${siteDomain}/`;
  const surveyLink = `https://${siteDomain}/${thisEvent?.pre_reg_hash}`;

  const generateEventAddress = () => {
    const location = thisEvent?.event_custom_data ? JSON.parse(thisEvent?.event_custom_data).event_location_type : '';
    if (location === "The School") {
      return `${charity?.address_1} ${charity?.address_2 || ''} ${charity?.city}, ${charity?.state} ${charity?.postal_code}`;
    } else if (location === "The Dealership") {
      return `${dealer?.address} ${dealer?.address2 || ''} ${dealer?.city}, ${dealer?.state} ${dealer?.zip}`;
    } else {
      return `${thisEvent?.address} ${thisEvent?.city}, ${thisEvent?.state} ${thisEvent?.zip_code}`;
    }
  }

  const generateDealerNotes = (dealer_notes: DealerNote) => {
    if (!dealer_notes) {
      return 'No Notes'
    }
    const formattedNotes = JSON.parse(dealer_notes.toString())
      .sort((a: any, b: any) => moment(a.created_at).diff(moment(b.created_at)))
      .map((note: any) => {
        const formattedDateTime = moment(note.created_at).format("MMM DD, YYYY hh:mm A");
        return `${formattedDateTime} - ${note.created_by}: ${note.note}`;
      });

    return (
      <td>
        {formattedNotes.map((note: string, index: number) => (
          <div key={index} style={{ borderBottom: "1px solid lightgrey" }}>{note}</div>
        ))}
      </td>
    );
  }

  return (
    <div id="Eligibility" className="eligibility-page">
      {shareChangesVisible && (
        <Dialog title={"Please confirm"} onClose={() => setShareChangesVisible(false)}>
          <p style={{ margin: "25px", textAlign: "center" }}>
            Do you want to notify the dealer ({dealer?.primary_contact} at {dealer?.dealer_name}) that changes were made to their event?
          </p>
          <DialogActionsBar>
            <Button
              className="ford-blue-btn"
              onClick={() => {
                setFormDisplayed("none");
                history.push('/events');
              }}
            >
              No
            </Button>
            <Button
              className="ford-blue-btn"
              onClick={() => {
                window.location.href = `mailto:${dealer?.contact_email}?subject=Notification%20of%20Changes%20to%20Your%20D4URS%20Event`
                setFormDisplayed("none");
                history.push('/events');
              }}
            >
              Yes, Open My Email
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
      {dialogDisplayed === "approveEligibility" && (
        <Dialog>
          <h3>Assign a Planner</h3>
          <Form
            onSubmit={onSubmitPlanner}
            initialValues={{
              ...formValues,
            }}
            render={(formRenderProps) => (
              <FormElement horizontal={true} style={{ width: 250 }}>
                {formError && <Error>{formError}</Error>}
                <Field
                  component={DropDownList}
                  id={"assigned_planner"}
                  name={"assigned_planner"}
                  label={localizationService.toLanguageString(
                    "custom.assigned_planner",
                    "assigned_planner"
                  )}
                  data={planners}
                  dataItemKey="user_id"
                  textField="display_name"
                  required={true}
                />
                <br />
                <DialogActionsBar>
                  <Button
                    className="ford-blue-btn"
                    primary={true}
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                  >
                    {localizationService.toLanguageString(
                      "custom.save",
                      "save"
                    )}
                  </Button>
                </DialogActionsBar>
              </FormElement>
            )}
          />
        </Dialog>
      )}
      {dialogDisplayed === "denyPlan" && (
        <Dialog>
          <h3>Deny Plan</h3>
          <Form
            onSubmit={onDenyPlan}
            initialValues={{
              ...formValues,
            }}
            render={(formRenderProps) => (
              <FormElement horizontal={true}>
                <p>Please enter a message for the Dealer.</p>
                {formError && <Error>{formError}</Error>}
                <Field
                  component={TextArea}
                  id={"deny_message"}
                  name={"deny_message"}
                  label={localizationService.toLanguageString(
                    "custom.deny_plan_reason",
                    "deny_plan_reason"
                  )}
                  required={true}
                />
                <DialogActionsBar>
                  <Button
                    className="ford-blue-btn"
                    primary={true}
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                  >
                    {localizationService.toLanguageString(
                      "custom.deny_plan",
                      "deny_plan"
                    )}
                  </Button>
                </DialogActionsBar>
              </FormElement>
            )}
          />
        </Dialog>
      )}
      {dialogDisplayed === "approvePlan" && (
        <Dialog>
          <h3>Approve Event</h3>
          <Form
            onSubmit={onSubmitFinalApproval}
            ignoreModified={true}
            initialValues={{
              ...formValues,
            }}
            render={(formRenderProps) => (
              <FormElement horizontal={true}>
                <p>
                  Are you sure you want to approve this event? The dealer will
                  be notified immediately.
                </p>
                {formError && <Error>{formError}</Error>}
                <DialogActionsBar>
                  <Button
                    className="ford-blue-btn"
                    primary={true}
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                  >
                    {localizationService.toLanguageString(
                      "custom.approve_event",
                      "approve_event"
                    )}
                  </Button>
                  &nbsp;
                  <Button
                    className="ford-blue-btn"
                    onClick={() => setDialogDisplayed("none")}>
                    {localizationService.toLanguageString(
                      "custom.cancel",
                      "cancel"
                    )}
                  </Button>
                </DialogActionsBar>
              </FormElement>
            )}
          />
        </Dialog>
      )}
      {dialogDisplayed === "reimbursement" && (
        <Dialog>
          <h3>Approve Reimbursement</h3>
          <Form
            onSubmit={
              addressVerified
                ? onSubmitReimbursementApproval
                : onSubmitReimbursementApprovalForceAddress
            }
            ignoreModified={true}
            render={(formRenderProps) => (
              <FormElement horizontal={true}>
                <p>
                  Are you sure you want to approve this reimbursement? The
                  dealer will be notified immediately.
                </p>
                <Field
                  component={NumericTextBox}
                  id={"reimbursement_amount"}
                  childRef={ref}
                  name={"reimbursement_amount"}
                  format="c2"
                  label={localizationService.toLanguageString(
                    "custom.reimbursement_amount",
                    "Reimbursement Amount"
                  )}
                  required={true}
                />
                {!addressVerified && (
                  <>
                    <div className="address_unverified_text">
                      <p>
                        This retailer address could not be verified by USPS.
                        Please see below and confirm that the address is
                        correct. If not, select "Cancel" and "View/Edit
                        Dealer" to edit the address.
                      </p>
                      <p>
                        If the address is correct, press "Confirm Address" to
                        continue the reimbursement process.
                      </p>
                    </div>
                    <div className="dealer_address_cntr">
                      <p>
                        {dealer?.address} {dealer?.address2}
                      </p>
                      <p>
                        {dealer?.city}, {dealer?.state} {dealer?.zip}
                      </p>
                    </div>
                  </>
                )}
                {formError && <Error>{formError}</Error>}
                <DialogActionsBar>
                  <Button
                    className="ford-blue-btn"
                    primary={true}
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                  >
                    {localizationService.toLanguageString(
                      "custom.approve_reimbursement",
                      "Approve Reimbursement"
                    )}
                  </Button>
                  &nbsp;
                  <Button
                    className="ford-blue-btn"
                    onClick={() => setDialogDisplayed("none")}>
                    {localizationService.toLanguageString(
                      "custom.cancel",
                      "cancel"
                    )}
                  </Button>
                </DialogActionsBar>
              </FormElement>
            )}
          />
        </Dialog>
      )}
      {dialogDisplayed === "approveDonation" && (
        <Dialog className="approve_donation_dialog">
          <h3>Approve Donation</h3>
          <Form
            onSubmit={
              addressVerified
                ? onSubmitDonationApproval
                : onSubmitDonationApprovalForceAddress
            }
            ignoreModified={true}
            initialValues={{
              ...formValues,
            }}
            render={(formRenderProps) => (
              <FormElement horizontal={true}>
                <p>Event Notes (optional)</p>
                <Field
                  component={TextArea}
                  id={"event_summary"}
                  name={"event_summary"}
                  label={localizationService.toLanguageString(
                    "custom.event_summary",
                    "event_summary"
                  )}
                  required={false}
                />
                <p>Total Donation Amount:</p>
                <Field
                  component={Input}
                  id={"check_amount"}
                  name={"check_amount"}
                  validator={dollarValidator}
                />
                <p>Valid Post-Drive Surveys:</p>
                <Field
                  component={Input}
                  id={"valid_post_count"}
                  name={"valid_post_count"}
                  validator={positiveNumberValidator}
                />
                {thisEvent?.bonus_event ? (
                  <>
                    <p>Valid Bonus Surveys:</p>
                    <Field
                      component={Input}
                      id={"bonus_valid_count"}
                      name={"bonus_valid_count"}
                      validator={positiveNumberValidator}
                    />
                  </>
                ) : null}

                {!addressVerified && (
                  <>
                    <div className="address_unverified_text">
                      <p>
                        This address could not be verified by USPS.
                        Please see below and confirm that the address is
                        correct. If not, select "Cancel" and "View/Edit
                        Dealer" to edit the address.
                      </p>
                      <p>
                        If the address is correct, press "Confirm Address" to
                        continue the donation process.
                      </p>
                    </div>
                    <div className="dealer_address_cntr">
                      <p>
                        {dealer?.address} {dealer?.address2}
                      </p>
                      <p>
                        {dealer?.city}, {dealer?.state} {dealer?.zip}
                      </p>
                    </div>
                  </>
                )}
                {formError && <Error>{formError}</Error>}
                <DialogActionsBar>
                  {addressVerified ? (
                    <Button
                      className="ford-blue-btn"
                      primary={true}
                      type={"submit"}
                      disabled={!formRenderProps.allowSubmit}
                    >
                      {localizationService.toLanguageString(
                        "custom.approve_donation",
                        "approve_donation"
                      )}
                    </Button>
                  ) : (
                    <Button
                      className="ford-blue-btn"
                      primary={true}
                      type={"submit"}
                      onClick={() => { }}
                      disabled={!formRenderProps.allowSubmit}
                    >
                      {localizationService.toLanguageString(
                        "custom.confirm_address",
                        "Confirm Address"
                      )}
                    </Button>
                  )}
                  &nbsp;
                  <Button
                    className="ford-blue-btn"
                    onClick={() => {
                      toggleAddressVerified(true);
                      setDialogDisplayed("none");
                    }}
                  >
                    {localizationService.toLanguageString(
                      "custom.cancel",
                      "cancel"
                    )}
                  </Button>
                </DialogActionsBar>
              </FormElement>
            )}
          />
        </Dialog>
      )}
      {dialogDisplayed === "none" && (
        <div className="card-container" style={{ maxWidth: 700 }}>
          <h1 className="card-title" style={fontBold}>
            {localizationService.toLanguageString(
              "custom.event_details",
              "event_details"
            )}
          </h1>

          <div className="card-component">
            <br />
            <h3>
              <span style={fontBold}>Status: </span> {capitalize(thisEvent?.status)}
              {
                // Eligibility buttons
                thisEvent?.status === "new" &&
                login.user?.credentials.hasPermission('admin') && (
                  <p>
                    <Button
                      className="ford-blue-btn"
                      onClick={() => setDialogDisplayed("approveEligibility")}
                    >
                      Approve Eligibility
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                      className="ford-blue-btn"
                      onClick={rejectEligibility}>
                      Reject Eligibility
                    </Button>
                  </p>
                )
              }

              {thisEvent?.event_id && (
                <p>
                  <Button
                    className="ford-blue-btn"
                    onClick={() => doMagicLink()}>
                    Event Portal Login
                  </Button>
                </p>
              )}
              {thisEvent?.status === "event ended" && (
                <p>
                  <Button
                    className="ford-blue-btn"
                    onClick={() => setDialogDisplayed("approveDonation")}>
                    Approve Donation
                  </Button>
                </p>
              )}
            </h3>
            <hr />
            {formDisplayed === "none" && thisEvent?.event_id && (
              <>
                <h3 style={fontBold}>Event Details{" "}
                  <Button
                    className="ford-blue-btn"
                    onClick={() => setFormDisplayed('event')} disabled={['cancelled', 'ineligible'].includes(thisEvent?.status) || moment(thisEvent?.start_date).isBefore(moment())}>
                    View/Edit Event
                  </Button>
                </h3>
                <section>
                  <span style={fontSemiBold}>Name: </span>{thisEvent?.event_name}
                  <br />
                  <span style={fontSemiBold}>Event Portal: </span>{" "}
                  <a href={eventPortalLink} target="_blank" rel="noreferrer">
                    {eventPortalLink}
                  </a>
                  <br />
                  <span style={fontSemiBold}>Latitude Event ID: </span>{thisEvent.event_id}
                  <br />
                  <span style={fontSemiBold}>FFS Event ID: </span>{thisEvent.ffs_event_id}
                  <br />
                  <span style={fontSemiBold}>Event Password: </span>{thisEvent.digital_event_code}
                  <br />
                  <span style={fontSemiBold}>Event Survey URL: </span>{" "}
                  <a href={surveyLink} target="_blank" rel="noreferrer">
                    {surveyLink}
                  </a>
                  <br />
                  <span style={fontSemiBold}>Event Date: </span>{" "}
                  {moment(thisEvent.start_date).format("dddd, MMMM Do")}{" "}
                  {formatRange(thisEvent.start_time, thisEvent.end_time)}
                  <br />
                  <span style={fontSemiBold}>Organization: </span>{thisEvent?.charity_partner}<br />
                  <span style={fontSemiBold}>Secondary Organization: </span>{thisEvent?.secondary_charity || 'N/A'}<br />
                  <span style={fontSemiBold}>Event Location Type: </span>{thisEvent?.event_location_type}<br />
                  <span style={fontSemiBold}>Check Payable: </span>{thisEvent?.check_payable}<br />
                  <span style={fontSemiBold}>Funding Purpose: </span>{thisEvent?.funding_purpose}<br />
                  <span style={fontSemiBold}>Event Address: </span>{generateEventAddress()}<br />
                </section>
                <hr />
              </>
            )}

            {formDisplayed === "event" && (
              <Form
                onSubmit={onSubmitUpdateEvent}
                initialValues={{
                  ...formValues,
                  ...thisEvent,
                  event_type: thisEvent?.event_type,
                }}
                render={(formRenderProps) => (
                  <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                    <h3>Edit Event Plan</h3>
                    {formError && <Error>{formError}</Error>}
                    <Field
                      id={"event_name"}
                      name={"event_name"}
                      maxLength={25}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.event_name",
                        "event_name"
                      )}
                      validator={requiredValidator}
                      component={Input}
                    />
                    <Field
                      id={"funding_purpose"}
                      name={"funding_purpose"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.funding_purpose",
                        "funding_purpose"
                      )}
                      validator={requiredValidator}
                      component={Input}
                    />
                    <Field
                      id={"start_date"}
                      name={"start_date"}
                      label={localizationService.toLanguageString(
                        "custom.event_date",
                        "event_date"
                      )}
                      childRef={dateRef}
                      format="MM/dd/yyyy"
                      min={moment().toDate()}
                      max={moment("2024-12-30").toDate()}
                      component={DatePicker}
                      validator={requiredValidator}
                      onChange={(e) => {
                        if (moment(e.value).diff(moment(), 'days') < 30) {
                          setShowRRDMessage(true)
                        } else {
                          setShowRRDMessage(false)
                        }
                      }}
                    />
                    {/* {(showRRDMessage) && <p style={{ color: 'red', fontStyle: 'bold' }}>This new event date is within 30 days. Please communicate any changes to RRD.</p>} */}
                    <Field
                      id={"secondary_charity"}
                      name={"secondary_charity"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.secondary_charity",
                        "Secondary Organization"
                      )}
                      validator={secondaryOrgDeleted || !thisEvent?.secondary_charity ? [] : requiredValidator}
                      component={Input}
                    />
                    <Field
                      id={"check_payable"}
                      name={"check_payable"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.check_payable",
                        "Check Payable To"
                      )}
                      validator={requiredValidator}
                      component={Input}
                    />
                    <Button
                      className="ford-blue-btn"
                      onClick={(e) => {
                        e.preventDefault()
                        formRenderProps.onChange("secondary_charity", { value: '' });
                        deleteSecondaryOrg(true)
                      }}
                      style={{ backgroundColor: 'red', color: 'white', width: '300px' }}
                      disabled={!thisEvent?.secondary_charity || secondaryOrgDeleted}
                    >
                      {secondaryOrgDeleted ? 'Organization Deleted' : 'Delete Existing Secondary Organization'}
                    </Button>
                    <p style={{ margin: 0, padding: 0, textAlign: 'left', fontSize: 10 }}>Changes are not permanent until form is saved, so if deleted on accident, just refresh the page.</p>
                    <Field
                      id={'event_location_type'}
                      name={'event_location_type'}
                      data={['The School', 'The Dealership', 'Other']}
                      // value={thisEvent?.event_location_type}
                      label={`Event Location`}
                      // validator={requiredValidator}
                      hint={`Please select the option that best describes the location of the event. If "Other", the address fields below are required.`}
                      component={DropDownList}
                      defaultValue={thisEvent?.event_location_type}
                      onChange={(e) => {
                        if (e.target.value === 'Other') {
                          setDisplayOtherAddressForm(true)
                        } else {
                          setDisplayOtherAddressForm(false)
                        }
                      }}
                    />
                    {(displayOtherAddressForm || (thisEvent?.event_location_type === 'Other' && !['The School', 'The Dealership'].includes(formRenderProps.valueGetter("event_location_type")))) &&
                      <div>
                        <Field
                          id={"address"}
                          name={"address"}
                          // type={'input'}
                          label={localizationService.toLanguageString(
                            "custom.address",
                            "address"
                          )}
                          validator={displayOtherAddressForm ? address1Validator : []}
                          component={Input}
                        />
                        <Field
                          id={"city"}
                          name={"city"}
                          // type={'input'}
                          label={localizationService.toLanguageString(
                            "custom.city",
                            "city"
                          )}
                          validator={displayOtherAddressForm ? cityValidator : []}
                          component={Input}
                        />
                        <Field
                          id={"state"}
                          name={"state"}
                          // type={'input'}
                          label={localizationService.toLanguageString(
                            "custom.state",
                            "state"
                          )}
                          validator={displayOtherAddressForm ? stateValidator : []}
                          component={Input}
                        />
                        <Field
                          id={"zip_code"}
                          name={"zip_code"}
                          // type={'input'}
                          label={localizationService.toLanguageString(
                            "custom.zip",
                            "zip"
                          )}
                          validator={displayOtherAddressForm ? zipValidator : []}
                          component={Input}
                        />
                      </div>
                    }
                    <hr />
                    <p style={{ color: 'red', fontStyle: 'bold' }}>This event has {registrants?.completed_registrations} pre-registrations.</p>
                    {moment(thisEvent?.start_date).diff(moment(), 'days') < 30 && <p style={{ color: 'red', fontStyle: 'bold' }}>This event is (or was) scheduled within 30 days. Please communicate any Event Date changes to RRD.</p>}
                    <div className={"k-form-buttons"}>
                      <Button
                        className="ford-blue-btn"
                        primary={true}
                        type={"submit"}
                        disabled={!formRenderProps.allowSubmit}
                      >
                        {localizationService.toLanguageString(
                          "custom.save",
                          "save"
                        )}
                      </Button>

                      <Button
                        className="ford-blue-btn"
                        onClick={() => {
                          setFormDisplayed("none");
                          deleteSecondaryOrg(false);
                          setDisplayOtherAddressForm(false);
                        }}>
                        {localizationService.toLanguageString(
                          "custom.cancel",
                          "cancel"
                        )}
                      </Button>
                    </div>
                  </FormElement>
                )}
              />
            )}

            {formDisplayed === "none" && (
              <>
                <h3 style={fontBold}>
                  Dealer{" "}
                  <Button
                    className="ford-blue-btn"
                    onClick={() => setFormDisplayed("dealer")}>
                    View/Edit Dealer
                  </Button>
                </h3>
                <section>
                  <span style={fontSemiBold}>Name: </span>{dealer?.dealer_name}
                  <br />
                  <span style={fontSemiBold}>Sales Code: </span>{dealer?.parts_code}
                  <br />
                  <span style={fontSemiBold}>Contact: </span>{dealer?.primary_contact}
                  <br />
                  <span style={fontSemiBold}>Contact Email: </span>{dealer?.contact_email}
                  <br />
                  <span style={fontSemiBold}>Contact Phone: </span>{dealer?.contact_phone}
                  <br />

                  <span style={fontSemiBold}>Notes: </span>{generateDealerNotes(dealer?.dealer_notes)}
                </section>
                <p style={{ fontWeight: 700, marginBottom: 0 }}>
                  Kit Shipment Address:
                </p>
                {
                  thisEvent?.kit_location === "The School address provided" ?
                    <section style={{ marginTop: 0 }}>
                      <span style={fontSemiBold}>Notes: </span>{charity?.address_1}
                      <br />
                      <span style={fontSemiBold}>Apt/Suite/Other: </span>{charity?.address_2}
                      <br />
                      <span style={fontSemiBold}>City: </span>{charity?.city}
                      <br />
                      <span style={fontSemiBold}>State: </span>{charity?.state}
                      <br />
                      <span style={fontSemiBold}>Zip: </span>{charity?.postal_code}
                      <br />
                    </section>
                    :
                    <p style={{ marginTop: 0 }}>
                      <span style={fontSemiBold}>Address: </span>{dealer?.address}
                      <br />
                      <span style={fontSemiBold}>Apt/Suite/Other: </span>{dealer?.address2}
                      <br />
                      <span style={fontSemiBold}>City: </span>{dealer?.city}
                      <br />
                      <span style={fontSemiBold}>State: </span>{dealer?.state}
                      <br />
                      <span style={fontSemiBold}>Zip: </span>{dealer?.zip}
                      <br />
                    </p>
                }
                <p style={{ fontWeight: 700, marginBottom: 0 }}>
                  Check Mailing Address:
                </p>
                {
                  thisEvent?.check_location === "The School address provided" ?
                    <p style={{ marginTop: 0 }}>
                      <span style={fontSemiBold}>Address: </span>{charity?.address_1}
                      <br />
                      <span style={fontSemiBold}>Apt/Suite/Other: </span>{charity?.address_2}
                      <br />
                      <span style={fontSemiBold}>City: </span>{charity?.city}
                      <br />
                      <span style={fontSemiBold}>State: </span>{charity?.state}
                      <br />
                      <span style={fontSemiBold}>Zip: </span>{charity?.postal_code}
                      <br />
                    </p>
                    :
                    <p style={{ marginTop: 0 }}>
                      <span style={fontSemiBold}>Address: </span>{dealer?.address}
                      <br />
                      <span style={fontSemiBold}>Apt/Suite/Other: </span>{dealer?.address2}
                      <br />
                      <span style={fontSemiBold}>City: </span>{dealer?.city}
                      <br />
                      <span style={fontSemiBold}>State: </span>{dealer?.state}
                      <br />
                      <span style={fontSemiBold}>Zip: </span>{dealer?.zip}
                      <br />
                    </p>
                }
                <hr />
              </>
            )}
            {formDisplayed === "dealer" && (
              <Form
                onSubmit={onSubmitDealer}
                initialValues={{
                  ...formValues,
                  ...dealer,
                }}
                render={(formRenderProps) => (
                  <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                    <h3>Edit Dealer</h3>
                    {formError && <Error>{formError}</Error>}
                    <Field
                      id={"dealer_name"}
                      name={"dealer_name"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.dealer_name",
                        "dealer_name"
                      )}
                      validator={requiredValidator}
                      component={Input}
                      maxLength={50}
                    />
                    <Field
                      id={"parts_code"}
                      name={"parts_code"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.sales_code",
                        "sales_code"
                      )}
                      validator={partsCodeValidator}
                      component={Input}
                      minLength={5}
                      maxLength={5}
                    />
                    <Field
                      id={"address"}
                      name={"address"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.address",
                        "address"
                      )}
                      validator={address1Validator}
                      component={Input}
                    />
                    <Field
                      id={"address2"}
                      name={"address2"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.address2",
                        "address2"
                      )}
                      component={Input}
                    />
                    <Field
                      id={"city"}
                      name={"city"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.city",
                        "city"
                      )}
                      validator={cityValidator}
                      component={Input}
                    />
                    <Field
                      id={"state"}
                      name={"state"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.state",
                        "state"
                      )}
                      validator={stateValidator}
                      component={Input}
                    />
                    <Field
                      id={"zip"}
                      name={"zip"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.zip",
                        "zip"
                      )}
                      validator={zipValidator}
                      component={Input}
                    />
                    <Field
                      id={"phone"}
                      name={"phone"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.dealershipPhone",
                        "dealershipPhone"
                      )}
                      validator={phoneValidator}
                      component={Input}
                    />

                    <Field
                      id={"primary_contact"}
                      name={"primary_contact"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.primary_contact",
                        "primary_contact"
                      )}
                      validator={requiredValidator}
                      component={Input}
                      maxLength={50}
                    />
                    <Field
                      id={"contact_phone"}
                      name={"contact_phone"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.contact_phone",
                        "contact_phone"
                      )}
                      validator={phoneValidator}
                      component={Input}
                    />
                    <Field
                      id={"contact_email"}
                      name={"contact_email"}
                      type={"email"}
                      autoComplete="username"
                      placeholder={"e.g.: peter@gmail.com"}
                      label={localizationService.toLanguageString(
                        "custom.contactEmail",
                        "Email"
                      )}
                      validator={emailValidator}
                      required={true}
                      component={Input}
                    />
                    <Field
                      id={"password"}
                      name={"password"}
                      autoComplete="new-password"
                      type={"password"}
                      label={localizationService.toLanguageString(
                        "custom.password",
                        "Password"
                      )}
                      validator={newPasswordValidator}
                      component={Input}
                    />
                    <Field
                      id={"confirm_password"}
                      name={"confirm_password"}
                      type={"password"}
                      autoComplete="new-password"
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.confirmPassword",
                        "Confirm Password"
                      )}
                      validator={passwordMatchValidator}
                      component={Input}
                    />
                    <Field
                      id={'new_dealer_note'}
                      name={'new_dealer_note'}
                      label={localizationService.toLanguageString('custom.dealerNote', 'New Dealer Note')}
                      // validator={passwordMatchValidator}
                      component={Input}
                    />
                    <hr />
                    <div className={"card-buttons"}>
                      <Button
                        className="ford-blue-btn"
                        type={"button"}
                        onClick={() => setFormDisplayed("none")}
                      >
                        {localizationService.toLanguageString(
                          "custom.cancel",
                          "cancel"
                        )}
                      </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Button
                        className="ford-blue-btn"
                        primary={true}
                        type={"submit"}
                        disabled={!formRenderProps.allowSubmit}
                      >
                        {localizationService.toLanguageString(
                          "custom.update",
                          "update"
                        )}
                      </Button>
                    </div>
                  </FormElement>
                )}
              />
            )}

            {formDisplayed === "none" && (
              <>
                <h3 style={fontBold}>
                  Organization{" "}
                  <Button
                    className="ford-blue-btn"
                    onClick={() => setFormDisplayed("charity")}>
                    View/Edit Organization
                  </Button>
                </h3>
                <p>
                  <span style={fontSemiBold}>Name: </span>{charity?.name}
                  {/* <br />
                  Tax ID: {charity?.tax_id} */}
                </p>
                <hr />
              </>
            )}
            {formDisplayed === "none" && thisEvent?.status && (
              <>
                <h3 style={fontBold}>
                  Donation Details{" "}
                </h3>

                <p>
                  <span style={fontSemiBold}>Donation Amount: </span>{" "}
                  {
                    thisEvent.check_amount_pretty
                  }
                  <br />
                </p>
                <hr />
              </>
            )}
            {formDisplayed === "none" && thisEvent?.status && (
              <>
                <h3 style={{ fontWeight: 700, marginBottom: 20 }}>
                  Resend Emails{" "}
                </h3>
                <Form
                  onSubmit={onSubmitSendEmails}
                  ref={formRef}
                  // initialValues={{
                  //   ...formValues,
                  //   ...charity,
                  // }}
                  render={(formRenderProps) => (
                    <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                      <Field
                        id={'email_type'}
                        name={'email_type'}
                        label={localizationService.toLanguageString('custom.email_type', 'Email Type')}
                        data={[
                          { id: 'pre_event', text: 'Pre-Event Info (training)' },
                          { id: 'event_approved', text: 'Event Approved' },
                          { id: 'kit_delivery', text: 'Kit Tracking Info' },
                        ]}
                        textField="text"
                        dataItemKey="id"
                        required={true}
                        validator={requiredValidator}
                        component={DropDownList}
                        onChange={(e) => {
                          setEmailType(e.target.value);
                        }}
                      />
                      {generateLastSentText(selectedEmailType)}
                      <Field
                        id={'send_to'}
                        name={'send_to'}
                        label={localizationService.toLanguageString('custom.send_to', 'Send To')}
                        required={true}
                        validator={requiredValidator}
                        component={Input}
                      />
                      <p className={"send-emails-subtext"}>Separate each address with a comma</p>
                      {errorMessage ? <p style={{ color: 'red' }}>{errorMessage}</p> : null}
                      {successMessage ? <p style={{ color: 'green' }}>{successMessage}</p> : null}
                      <div className={"card-buttons"}>
                        <Button
                          className="ford-blue-btn"
                          primary={true}
                          type={"submit"}
                        // disabled={!formRenderProps.allowSubmit || isLoading || ['cancelled', 'ineligible'].includes(thisEvent?.status) || moment(thisEvent?.start_date).endOf('day').isBefore(moment())}
                        >
                          {isLoading ? 'Sending...' : 'Send Email(s)'}
                        </Button>
                        {['cancelled', 'ineligible'].includes(thisEvent?.status) && <p style={{ fontSize: 12, color: '#F28888' }}>resending emails is disabled due to invalid event status ({thisEvent?.status}).</p>}
                      </div>
                    </FormElement>
                  )}
                ></Form>
              </>
            )}
            {formDisplayed === "charity" && (
              <Form
                onSubmit={onSubmitCharity}
                initialValues={{
                  ...formValues,
                  ...charity,
                }}
                render={(formRenderProps) => (
                  <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                    <h3>Edit Organization</h3>
                    {formError && <Error>{formError}</Error>}
                    <Field
                      id={"name"}
                      name={"name"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.charity_name",
                        "charity_name"
                      )}
                      validator={requiredValidator}
                      component={Input}
                    />
                    <Field
                      id={"address_1"}
                      name={"address_1"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.address_1",
                        "address_1"
                      )}
                      validator={requiredValidator}
                      component={Input}
                    />
                    <Field
                      id={"address_2"}
                      name={"address_2"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.address_2",
                        "address_2"
                      )}
                      component={Input}
                    />
                    <Field
                      id={"city"}
                      name={"city"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.city",
                        "city"
                      )}
                      validator={requiredValidator}
                      component={Input}
                    />
                    <Field
                      id={"state"}
                      name={"state"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.state",
                        "state"
                      )}
                      validator={requiredValidator}
                      component={Input}
                    />
                    <Field
                      id={"postal_code"}
                      name={"postal_code"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.zip",
                        "zip"
                      )}
                      validator={requiredValidator}
                      component={Input}
                    />
                    <Field
                      id={"contact_first_name"}
                      name={"contact_first_name"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.contact_first_name",
                        "contact_first_name"
                      )}
                      validator={requiredValidator}
                      component={Input}
                    />
                    <Field
                      id={"contact_last_name"}
                      name={"contact_last_name"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.contact_last_name",
                        "contact_last_name"
                      )}
                      validator={requiredValidator}
                      component={Input}
                    />
                    <Field
                      id={"contact_phone_number"}
                      name={"contact_phone_number"}
                      // type={'input'}
                      label={localizationService.toLanguageString(
                        "custom.contact_phone_number",
                        "contact_phone_number"
                      )}
                      required={true}
                      validator={phoneValidator}
                      mask="000-000-0000"
                      component={MaskedTextBox}
                    />
                    <Field
                      id={"contact_email"}
                      name={"contact_email"}
                      type={"email"}
                      placeholder={"e.g.: peter@gmail.com"}
                      label={localizationService.toLanguageString(
                        "custom.contact_email",
                        "Email"
                      )}
                      validator={emailValidator}
                      required={true}
                      component={Input}
                    />
                    <hr />
                    <div className={"card-buttons"}>
                      <Button
                        className="ford-blue-btn"
                        type={"button"}
                        onClick={() => setFormDisplayed("none")}
                      >
                        {localizationService.toLanguageString(
                          "custom.cancel",
                          "cancel"
                        )}
                      </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Button
                        className="ford-blue-btn"
                        primary={true}
                        type={"submit"}
                        disabled={!formRenderProps.allowSubmit}
                      >
                        {localizationService.toLanguageString(
                          "custom.update",
                          "update"
                        )}
                      </Button>
                    </div>
                  </FormElement>
                )}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default EventDetailScreen;
