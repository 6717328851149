import { observer } from "mobx-react-lite";
import { useLocalization } from "@progress/kendo-react-intl";
import { useStores } from "src/stores";
import { useEffect, useRef, useState } from "react";
import { useServices } from "src/services";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Button, ToolbarSpacer } from "@progress/kendo-react-buttons";
import { useHistory } from "react-router";
import { Dealer } from "src/utils/classes";
import { DataResult, process, State } from "@progress/kendo-data-query";
import {
  ColumnMenu,
  ColumnMenuCheckboxFilter,
} from "src/components/GridColumnMenus";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import moment from "moment-timezone";
import { set } from "mobx";

const WaitlistScreen = () => {
  const localizationService = useLocalization();

  const { ui } = useStores();
  const { d4ur } = useServices();
  let history = useHistory();

  const [dataState, setDataState] = useState<State>({
    filter: {
      logic: "and",
      filters: [
        {
          logic: "or",
          filters: [
            { field: "activeGridCell", operator: "eq", value: "Active" },
          ],
        },
      ],
    },
    sort: [
      // {
      //   field: "status",
      //   dir: "asc",
      // },
      // {
      //   field: "invited_off_waitlist",
      //   dir: "asc",
      // },
      {
        field: "waitlisted",
        dir: "asc",
      },
    ],
  });

  const [dealers, setDealers] = useState<Dealer[]>([]);
  const [copied, setCopied] = useState<boolean>(false);
  const [waitlistData, setWaitlistData] = useState<WaitlistData>({});
  const [dataResult, setDataResult] = useState<DataResult>(
    process(dealers, dataState)
  );
  const [localInvitesCopy, setLocalInvitesCopy] = useState<any[]>([]);

  const processDealers = (dealers: any[]): Dealer[] => {
    if (!Array.isArray(dealers)) {
      return [];
    }
    // filter out dealers that are not waitlisted 


    const waitlistedDealers = dealers
      .filter((d) => d.waitlisted !== null);

    return Array.from(new Set(waitlistedDealers.map((d) => d.dealer_id)))
      .map((dealerId) => waitlistedDealers.find((d) => d.dealer_id === dealerId))
      .map((d) => new Dealer(d));
    return Array.from(new Set(
      dealers
        .filter((d) => d.waitlisted !== null)
        .map((d) => new Dealer(d))
    ));
  };

  useEffect(() => {
    // ui.setIsGettingDealers(true);
    d4ur.getDealers().then((ret) => {
      const processedDealers = processDealers(
        (ret as D4URxFetchResult).result || []
      );
      // ui.setIsGettingDealers(false);
      d4ur.getWaitlistData().then((ret) => {
        setWaitlistData((ret as D4URxFetchResult).result as WaitlistData);
        setDealers(processedDealers);
        setDataResult(process(processedDealers, dataState));
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localInvitesCopy]);

  const DealerActionsCell = (props: GridCellProps) => {
    const { dealer_id } = props.dataItem as Dealer;

    return (
      <td>
        <Button
          className="k-button k-primary"
          onClick={() => {
            history.push(`/retailer/${dealer_id}`);
          }}
        >
          Edit
        </Button>
      </td>
    );
  };

  const waitlistedAtCell = (props: GridCellProps) => {
    const { waitlisted } = props.dataItem as Dealer;

    return (
      <td>
        {waitlisted
          ? moment(waitlisted)
            .subtract(4, "hours")
            .format("MMM Do, YYYY [at] h:mmA")
          : ""}
      </td>
    );
  };

  const dealerEmailCell = (props: GridCellProps) => {
    const { contact_email } = props.dataItem as Dealer;

    return (
      <td>
        <p
          style={{
            cursor: "pointer", whiteSpace: 'normal', wordWrap: 'break-word', fontSize: '14px'
          }}
          onClick={() => {
            navigator.clipboard.writeText(contact_email);
            setCopied(true);
            setTimeout(() => {
              setCopied(false);
            }, 2500);
          }}
        >
          {contact_email}
        </p>
      </td >
    );
  };

  const eventRequestedColumn = (props: GridCellProps) => {
    const { event_requested, event_requested_at, season_id } = props.dataItem as Dealer;
    // if (season_id !== ui.currentSeason.season_id) {
    //   return <td>N/A</td>;
    // }
    if (event_requested && season_id === ui.currentSeason.season_id) {
      return (
        <td>{`YES at ${moment(event_requested_at)
          .subtract(4, "hours")
          .format("MMM Do, YYYY [at] h:mmA")}`}</td>
      );
    }
    return <td>No</td>;
  };

  const eventStatusColumn = (props: GridCellProps) => {
    const { status, season_id } = props.dataItem as Dealer;
    if (season_id !== ui.currentSeason.season_id) {
      return <td>N/A</td>;
    }
    if (status === "approved") {
      return (
        <td style={{ color: "lightgreen" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "auto",
            }}
          >
            YES
          </div>
        </td>
      );
    } else if (status === "new") {
      return <td>AWAITING APPROVAL</td>;
    }
    return <td>{status?.toUpperCase()}</td>;
  };

  const inviteCell = (props: GridCellProps) => {
    const { invited_off_waitlist, event_requested, season_id, status } = props.dataItem as Dealer;

    const handleInviteOffWaitlist = (e: any) => {
      return d4ur
        .inviteOffWaitlist(
          (props.dataItem as Dealer).dealer_id,
          e.target.checked
        )
        .then(() => {
          return d4ur.getDealers().then((ret) => {
            const processedDealers = processDealers(
              (ret as D4URxFetchResult).result || []
            );
            setDealers(processedDealers);
            setDataResult(process(processedDealers, dataState));
          });
        });
    };

    return (
      <td>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "auto",
          }}
        >
          <input
            type="checkbox"
            checked={invited_off_waitlist !== null}
            onChange={handleInviteOffWaitlist}
            disabled={event_requested && status !== 'ineligible' && (season_id === ui.currentSeason.season_id)}
            style={{ width: "30px", height: "30px", cursor: "pointer" }}
          />
          {invited_off_waitlist
            ? `Marked as invited at ${moment(invited_off_waitlist)
              .subtract(4, "hours")
              .format("MMM Do, YYYY [at] h:mmA")}`
            : ""}
        </div>
      </td>
    );
  };

  const DealerNotesCell = (props: GridCellProps) => {
    const { dealer_notes } = props.dataItem as Dealer;

    if (
      !dealer_notes ||
      !dealer_notes.length ||
      !JSON.parse(dealer_notes.toString()).length
    ) {
      return <td></td>;
    }

    const formattedNotes = JSON.parse(dealer_notes.toString())
      .sort((a: any, b: any) => moment(a.created_at).diff(moment(b.created_at)))
      .map((note: any) => {
        const formattedDateTime = moment(note.created_at).format(
          "MMM DD, YYYY hh:mm A"
        );
        return `${formattedDateTime} - ${note.created_by}: ${note.note}`;
      });

    return (
      <td style={{ overflowY: "auto" }}>
        {formattedNotes.map((note: string, index: number) => (
          <div key={index} style={{ borderBottom: "1px solid lightgrey" }}>
            {note}
          </div>
        ))}
      </td>
    );
  };

  const _export = useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const filterExportData = (data: any[]) => {
    // loop through data, and if data.event_requested_at is prior than 2024, set that to null
    data.forEach((dealer) => {
      if (dealer.event_requested_at && moment(dealer.event_requested_at).isBefore('2024-01-01')) {
        dealer.event_requested_at = null;
      }
    });
    // sort data by dealer.id ascending
    data.sort((a, b) => a.dealer_id - b.dealer_id);
    // if waitlisted, set event.status to waitlisted
    data.forEach((dealer) => {
      if (dealer.invited_off_waitlist && !dealer.event_requested_at) {
        dealer.status = 'invited';
      }
      if ((dealer.status === 'approved' && !dealer.event_requested_at) || !dealer.status) {
        dealer.status = 'waitlisted';
      }
    });
    return data;
  }

  const rowRender = (row, props) => {
    let color = "#fff";
    if (props.dataItem.status === "approved" && props.dataItem.season_id === ui.currentSeason.season_id) {
      color = "grey";
    }
    let style = {
      ...row.props.style,
      backgroundColor: color,
      borderColor: "black",
      borderWidth: 2,
    };
    return <tr {...row.props} style={style}></tr>;
  };

  return (
    <>
      <div id="Dashboard" className="waitlist-page main-content">
        <div className="card-container grid">
          <div>
            <h3 className="card-title">
              {localizationService.toLanguageString(
                "custom.waitlist",
                "waitlist"
              )}
            </h3>
            <p>
              Waitlist Enabled: {waitlistData.waitlistEnabled ? "Yes" : "No"}
            </p>
            <p>
              Total Waitlist Count: {dealers.length}
            </p>
            <p>
              Total Invited Off Waitlist: {dealers.filter(dealer => dealer.invited_off_waitlist !== null).length}
            </p>
            <p>
              Total Waiting To Be Invited: {dealers.length - dealers.filter(dealer => dealer.invited_off_waitlist !== null).length}
            </p>
            <br />
            <br />
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigator.clipboard.writeText(
                  "https://enroll.d4urs.com/eligibility"
                );
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 2500);
              }}
            >
              Event Request Form URL (Click to Copy):
              https://enroll.d4urs.com/eligibility
            </p>
            {copied && <p style={{ color: "green" }}>Copied to clipboard!</p>}
          </div>
          {/* <p>Click the checkbox in the "Invited off Waitlist" column to mark a dealer as invited</p> */}
          {/* <Button primary={!ui.events.length} onClick={handleEventRequestButton}>
                        { ui.events.length ?
                            localizationService.toLanguageString('custom.requestNewEvent','requestNewEvent') :
                            localizationService.toLanguageString('custom.beginHere','beginHere')
                        }
                    </Button> */}
          <div className="card-component">
            <ExcelExport
              data={filterExportData(dataResult.data)}
              ref={_export}
              fileName={`dealers_export_${moment().format(
                "YYYYMMDD-hhmma"
              )}.xlsx`}
            >
              <ExcelExportColumn field="dealer_id" title="Lat. Dealer ID" />
              <ExcelExportColumn field="dealer_name" title="Dealer Name" />
              <ExcelExportColumn field="contact_email" title="Contact Email" />
              <ExcelExportColumn field="waitlisted" title="Waitlisted At" />
              <ExcelExportColumn
                field="invited_off_waitlist"
                title="Invited Off Waitlist At"
              />
              <ExcelExportColumn
                field="event_requested_at"
                title="Event Requested At"
              />
              <ExcelExportColumn field="status" title="Event Status" />
            </ExcelExport>
            <Grid
              data={dataResult}
              rowRender={rowRender}
              sortable={{ mode: "multiple" }}
              {...dataState}
              onDataStateChange={(event: GridDataStateChangeEvent) => {
                setDataResult(process(dealers, event.dataState));
                setDataState(event.dataState);
              }}
              resizable={true}
            >
              <GridToolbar>
                <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => setDataState({
                  filter: {
                    logic: "and",
                    filters: [
                      {
                        logic: "or",
                        filters: [
                          { field: "activeGridCell", operator: "eq", value: "Active" },
                        ],
                      },
                    ],
                  },
                })
                }>Clear All Sorting</button>
                <ToolbarSpacer />
                <button
                  className="k-button k-button-md k-rounded-md k-button-solid"
                  onClick={excelExport}
                >
                  Export
                </button>
              </GridToolbar>
              <GridColumn
                field="dealer_name"
                title={localizationService.toLanguageString(
                  "custom.dealer",
                  "dealer"
                )}
                columnMenu={ColumnMenu}
                width={150}
              />
              <GridColumn
                field="contact_email"
                title="Dealer Email (click to copy)"
                columnMenu={ColumnMenu}
                cell={dealerEmailCell}
                width={250}
              />
              <GridColumn
                field="waitlisted"
                title="Joined Waitlist"
                cell={waitlistedAtCell}
                columnMenu={ColumnMenu}
                width={200}
              />
              <GridColumn
                field="invited_off_waitlist"
                title="Invited Off Waitlist"
                cell={inviteCell}
                columnMenu={ColumnMenu}
                width={200}
              />
              <GridColumn
                field="event_requested"
                title="Event Requested"
                columnMenu={ColumnMenu}
                cell={eventRequestedColumn}
                width={200}
              />
              <GridColumn
                field="status"
                title="Event Approved"
                columnMenu={ColumnMenu}
                cell={eventStatusColumn}
                width={200}
              />
              {/* <GridColumn
                field="created_timestamp"
                title="Signup Date"
                format="{0:E, MMM dd yyyy}"
                filter="date"
                columnMenu={ColumnMenu}
              />
              <GridColumn
                field="approved_dealers"
                title="Allowed"
                columnMenu={ColumnMenuCheckboxFilter(dealers, {
                  searchBox: () => null,
                })}
              />
              <GridColumn
                field="activeGridCell"
                title="Status"
                columnMenu={ColumnMenuCheckboxFilter(dealers, {
                  searchBox: () => null,
                })}
              /> */}
              <GridColumn title="Dealer Notes" cell={DealerNotesCell} width={350} />
              <GridColumn cell={DealerActionsCell} width={300} />
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(WaitlistScreen);
