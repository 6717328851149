import * as React from "react";

import { useLocalization } from "@progress/kendo-react-intl";
import { useHistory } from "react-router";
import { Button } from "@progress/kendo-react-buttons";

import fordBlueLogo from "../assets/ford-blue.png";

// import kendoka from "../assets/kendoka.png";
// import github from "../assets/github-icon.svg";

const HomeScreen = () => {
  const localizationService = useLocalization();
  const history = useHistory();
  return (
    <div id="Home" className="main-content">
      <div className="home-page">
        <img src={fordBlueLogo} alt="ford" />
        <h1>D4URx Admin</h1>
        <div className="btn-group">
          <Button
            className="ford-blue-btn"
            onClick={() => {
              history.replace("/login");
            }}
          >
            {localizationService.toLanguageString("custom.logIn")}
          </Button>
          <a
            onClick={() => {
              history.replace("/password");
            }}
          >
            {localizationService.toLanguageString("custom.forgotPassword")}
          </a>
        </div>
      </div>
      <div className="footer" />
    </div>
  );
};

export default HomeScreen;
