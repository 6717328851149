import * as React from "react";
import * as PropTypes from "prop-types";

export const Header = (props) => {
  const { onButtonClick } = props;

  return (
    <header className="header">
      <div className="menu-button">
        <span className={"k-icon k-i-menu"} onClick={onButtonClick} />
      </div>
    </header>
  );
};

Header.displayName = "Header";
Header.propTypes = {
  page: PropTypes.string,
  onButtonClick: PropTypes.func,
};
