/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { useParams } from "react-router-dom";

import { useLocalization } from '@progress/kendo-react-intl';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Error } from "@progress/kendo-react-labels";
import {
    emailValidator,
    requiredValidator,
    passwordMatchValidator,
    address1Validator,
    cityValidator,
    stateValidator,
    zipValidator,
    phoneValidator,
    newPasswordValidator,
    partsCodeValidator
} from '../validators';
// import raw_timezones from 'src/stores/timezones.json';

import { AppContext } from '../AppContext'
import { useServices } from 'src/services';
import { Input } from 'src/components/Form/Input';
import { MaskedTextBox } from 'src/components/Form/MaskedTextBox';
import { useHistory } from 'react-router';
import moment from 'moment';
import capitalize from 'src/utils/capitalize';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList } from 'src/components/Form/DropDownList';
import { DatePicker } from 'src/components/Form/DatePicker';
import { NumericTextBox } from 'src/components/Form/NumericTextBox';
import { TextArea } from '@progress/kendo-react-inputs';
import { useStores } from 'src/stores';
import { Dealer, D4URxEvent, UserDetails } from 'src/utils/classes';

type EligibilityParams = {
    event_request_id: string;
};


const dealerTypes = [
    { value: 'F', text: 'Ford' },
    { value: 'FL', text: 'Ford/Lincoln' }
];

const dealerRegions = [
    { value: 'CE', text: 'Central Market Area' },
    { value: 'EMA', text: 'East Market Area' },
    { value: 'GL', text: 'Great Lakes Market Area' },
    { value: 'SE', text: 'Southeast Market Area' },
    { value: 'W', text: 'West Market Area' },
]

const fontBold = { fontWeight: 700 }
const fontSemiBold = { fontWeight: 600 }

const EventPlanningFormScreen = () => {
    const { ...formValues } = React.useContext(AppContext);
    const localizationService = useLocalization();

    const { d4ur } = useServices();
    const { login } = useStores();
    let history = useHistory();
    const { event_request_id } = useParams<EligibilityParams>();

    const [dialogDisplayed, setDialogDisplayed] = useState<string>('none');
    const [formDisplayed, setFormDisplayed] = useState<string>('none');
    const [formError, setFormError] = useState<string>();

    const [planners, setPlanners] = useState<UserDetails[]>([]);

    const [thisEvent, setThisEvent] = useState<D4URxEvent>();
    const [dealer, setDealer] = useState<Dealer>();
    const [charity, setCharity] = useState<Charity>();
    const [assignedPlanner, setAssignedPlanner] = useState<UserDetails>();
    // const [timeZones, setTimeZones] = useState<string[]>();

    // const [files, setFiles] = React.useState<Array<UploadFileInfo>>([]);

    // const onAdd = (event: UploadOnAddEvent) => {
    //     console.log("onAdd: ", event.affectedFiles);

    //     setFiles(event.newState);
    // };

    // const onRemove = (event: UploadOnRemoveEvent) => {
    //     console.log("onRemove: ", event.affectedFiles);

    //     setFiles(event.newState);
    // };

    // const onProgress = (event: UploadOnProgressEvent) => {
    //     console.log("onProgress: ", event.affectedFiles);

    //     setFiles(event.newState);
    // };

    // const onStatusChange = (event: UploadOnStatusChangeEvent) => {
    //     console.log("onStatusChange: ", event.affectedFiles);

    //     setFiles(event.newState);
    // };

    // TODO: use the season not hard-coded dates
    const programStart = moment();
    const programEnd = moment('2022-11-30');
    let interim = programStart.clone();
    const months: string[] = [];
    while (programEnd > interim || interim.format('M') === programEnd.format('M')) {
        months.push(interim.format('MMMM'));
        interim.add(1, 'month');
    }

    const getRequestData = () => {
        // setTimeZones(raw_timezones);
        d4ur.getPlanners().then((plannersResult) => {
            const plannersResultData = (plannersResult as D4URxFetchResult).result as UserDetails[]
            setPlanners(plannersResultData);
            d4ur.getEventRequest(event_request_id).then((ret) => {
                const {
                    event,
                    dealer,
                    charity,
                } = (ret as D4URxFetchResult).result;

                const { assigned_planner } = JSON.parse(event.eligibility_custom_data || '{}');

                charity.address_2 = charity.address_2 || '';

                setAssignedPlanner(plannersResultData.find(x => x.user_id === assigned_planner));
                setThisEvent(new D4URxEvent(event));
                setDealer(new Dealer(dealer));
                setCharity(charity as Charity);
            });
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getRequestData, []);

    const onSubmitDealer = React.useCallback(
        async (dataItem) => {
            setFormDisplayed('none');

            if (!dataItem.confirm_password) {
                delete dataItem.password;
            }

            const call: any = await d4ur.updateDealer(dataItem);
            if (call.result?.errors) {
                console.log('[EventPlanningFormScreen]', 'Update Dealer Error');
                setFormError(call.result.errors.join('\n'));
            } else {
                console.log('[EventPlanningFormScreen]', 'dealer updated');
                history.push('/events');
            }
        },
        [d4ur, history]
    );

    const onSubmitCharity = React.useCallback(
        async (dataItem) => {
            console.log('dataItem!!!!!!!!', dataItem);
            setFormDisplayed('none');

            const call: any = await d4ur.updateCharity(dataItem);
            if (call.result.errors) {
                console.log('[EventPlanningFormScreen]', 'Update Charity Error');
                setFormError(call.result.errors.join('\n'));
            } else {
                console.log('[EventPlanningFormScreen]', 'charity updated');
                history.push('/events');
            }
        },
        [d4ur, history]
    );

    const onSubmitEligibility = React.useCallback(
        async (dataItem) => {
            console.log('dataItem!!!!!!!!', dataItem);
            setFormDisplayed('none');

            let customData = JSON.parse(thisEvent!.eligibility_custom_data || '{}');
            customData = {
                ...customData,
                requested_month: dataItem.requested_month,
                requested_date: dataItem.requested_date,
                assigned_planner: dataItem.assigned_planner?.user_id,
            }

            dataItem.eligibility_custom_data = JSON.stringify(customData);

            const call: any = await d4ur.updateEligibility(new D4URxEvent(dataItem));
            if (call.result.errors) {
                console.log('[EventPlanningFormScreen]', 'Update Event Request Error');
                setFormError(call.result.errors.join('\n'));
            } else {
                console.log('[EventPlanningFormScreen]', 'Event Request updated');
                history.push('/events');
            }
        },
        [d4ur, history, thisEvent]
    );

    const onSubmitPlanner = React.useCallback(
        async (dataItem) => {
            console.log('dataItem!!!!!!!!', dataItem);
            d4ur.setEligibleEventRequest(thisEvent!.event_request_id!, dataItem.assigned_planner.user_id).then(res => {
                setDialogDisplayed('none');
                history.push('/events');
            });
        },
        [d4ur, history, thisEvent]
    );

    const rejectEligibility = () => {
        d4ur.setIneligibleEventRequest(thisEvent!.event_request_id!).then(res => {
            setDialogDisplayed('none');
            history.push('/events');
        });
    };

    // const filterTimeZones = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
    //     return filterBy(raw_timezones, filter);
    // };

    // const filterTZ = (event: DropDownListFilterChangeEvent) => {
    //     setTimeZones(filterTimeZones(event.filter));
    // };

    // const formatRange = (start?: Date, end?: Date) => {
    //     let range = '';
    //     if (start) {
    //         range += moment(start).format('h:mm a');
    //     }
    //     if (start && end) {
    //         range += ' - ';
    //     }
    //     if (end) {
    //         range += moment(end).format('h:mm a');
    //     }

    //     return range;
    // }

    // const { centuryParty, file_id, file_name } = JSON.parse(thisEvent?.event_custom_data || '{}');

    // const onSubmitPlan = React.useCallback(
    //     async (dataItem) => {
    //         console.log('dataItem!!!!!!!!', dataItem);
    //         setFormDisplayed('none');

    //         let customData = JSON.parse(thisEvent!.eligibility_custom_data || '{}');
    //         customData = {
    //             ...customData,
    //             requested_month: dataItem.requested_month,
    //             requested_date: dataItem.requested_date,
    //         }

    //         dataItem.eligibility_custom_data = JSON.stringify(customData);

    //         let eventCustomData = JSON.parse(thisEvent!.event_custom_data || '{}');
    //         eventCustomData = {
    //             ...eventCustomData,
    //             centuryParty: dataItem.event_type?.value,
    //         }
    //         dataItem.event_custom_data = JSON.stringify(eventCustomData);
    //         dataItem.event_type = dataItem.event_type?.value;

    //         const [selectedFile] = files;
    //         const rawFile = selectedFile && selectedFile.getRawFile && selectedFile.getRawFile();
    //         // if (rawFile) {
    //         //     dataItem.event_plan_file = rawFile;
    //         // }

    //         const call: any = await d4ur.saveEventPlan(new D4URxEvent(dataItem), rawFile);
    //         if (call.result.errors) {
    //             console.log('[EventPlanningFormScreen]', 'Update Event Request Error');
    //             setFormError(call.result.errors.join('\n'));
    //         } else {
    //             console.log('[EventPlanningFormScreen]', 'Event Request updated');
    //             history.push('/events');
    //         }
    //     },
    //     [d4ur, files, history, thisEvent]
    // );

    // const doDenyPlan = () => {
    //     setDialogDisplayed('denyPlan');
    // };

    const onDenyPlan = React.useCallback(
        async (dataItem) => {
            console.log('dataItem!!!!!!!!', dataItem);
            d4ur.denyPlan(thisEvent!.event_request_id!, dataItem.deny_message).then(res => {
                setDialogDisplayed('none');
                history.push('/events');
            });
        },
        [d4ur, history, thisEvent]
    );

    // const statusesThatCanBeRejected = [
    //     'new', // needs eligibility confirmation
    //     'eligible', // is eligible, no event plans yet
    //     'planning', // in planning state
    //     'revise', // event plan denied
    //     'review', // submitted for review
    // ];

    // const doRejectPlan = () => {
    //     setDialogDisplayed('rejectPlan');
    // };

    const onRejectPlan = React.useCallback(
        async (dataItem) => {
            console.log('dataItem!!!!!!!!', dataItem);
            d4ur.rejectPlan(thisEvent!.event_request_id!, dataItem.reject_message).then(res => {
                setDialogDisplayed('none');
                history.push('/events');
            });
        },
        [d4ur, history, thisEvent]
    );

    const onSubmitFinalApproval = React.useCallback(
        async (dataItem) => {
            const call: any = await d4ur.submitFinalApproval(thisEvent?.event_request_id);
            if (call.result.errors) {
                console.log('[EventPlanningFormScreen]', 'Event Approved Error');
                setFormError(call.result.errors.join('\n'));
            } else {
                console.log('[EventPlanningFormScreen]', 'Event Approved');
                history.push('/events');
            }
        },
        [d4ur, history, thisEvent]
    );

    const attendanceRef: any = React.useRef(null);
    const attendanceRef2: any = React.useRef(null);
    const dateRef: any = React.useRef(null);
    const requestedDateRef: any = React.useRef(null);
    const startTimeRef: any = React.useRef(null);
    const endTimeRef: any = React.useRef(null);

    const handleOnWheel = (event: any) => {
        event.stopPropagation();
    };

    React.useEffect(() => {
        attendanceRef?.current?.element.addEventListener('wheel', handleOnWheel);
        attendanceRef2?.current?.element.addEventListener('wheel', handleOnWheel);
        dateRef?.current?.element.addEventListener('wheel', handleOnWheel);
        requestedDateRef?.current?.element.addEventListener('wheel', handleOnWheel);
        startTimeRef?.current?.element.addEventListener('wheel', handleOnWheel);
        endTimeRef?.current?.element.addEventListener('wheel', handleOnWheel);

        return () => {
            attendanceRef?.current?.element.removeEventListener('wheel', handleOnWheel);
            attendanceRef2?.current?.element.removeEventListener('wheel', handleOnWheel);
            dateRef?.current?.element.removeEventListener('wheel', handleOnWheel);
            requestedDateRef?.current?.element.removeEventListener('wheel', handleOnWheel);
            startTimeRef?.current?.element.removeEventListener('wheel', handleOnWheel);
            endTimeRef?.current?.element.removeEventListener('wheel', handleOnWheel);
        };
    });

    const generateDealerNotes = (dealer_notes: DealerNote) => {
        if (!dealer_notes) {
            return 'No Notes'
        }
        const formattedNotes = JSON.parse(dealer_notes.toString())
            .sort((a: any, b: any) => moment(a.created_at).diff(moment(b.created_at)))
            .map((note: any) => {
                const formattedDateTime = moment(note.created_at).format("MMM DD, YYYY hh:mm A");
                return `${formattedDateTime} - ${note.created_by}: ${note.note}`;
            });

        return (
            <td>
                {formattedNotes.map((note: string, index: number) => (
                    <div key={index} style={{ borderBottom: "1px solid lightgrey" }}>{note}</div>
                ))}
            </td>
        );
    }

    const generateEventAddress = () => {
        const location = thisEvent?.event_custom_data ? JSON.parse(thisEvent?.event_custom_data).event_location_type : '';
        if (location === "The School") {
            return `${charity?.address_1} ${charity?.address_2 || ''} ${charity?.city}, ${charity?.state} ${charity?.postal_code}`;
        } else if (location === "The Dealership") {
            return `${dealer?.address} ${dealer?.address2 || ''} ${dealer?.city}, ${dealer?.state} ${dealer?.zip}`;
        } else {
            return `${thisEvent?.address} ${thisEvent?.city}, ${thisEvent?.state} ${thisEvent?.zip_code}`;
        }
    }

    return (
        <div id="Eligibility" className="eligibility-page">
            {
                dialogDisplayed === 'approveEligibility' &&
                <Dialog>
                    <h3>Assign a Planner</h3>
                    <Form
                        onSubmit={onSubmitPlanner}
                        initialValues={{
                            ...formValues,
                        }}
                        render={(formRenderProps) => (
                            <FormElement horizontal={true} style={{ width: 250 }}>
                                {formError && <Error>{formError}</Error>}
                                <Field
                                    component={DropDownList}
                                    id={'assigned_planner'}
                                    name={'assigned_planner'}
                                    label={localizationService.toLanguageString('custom.assigned_planner', 'assigned_planner')}
                                    data={planners}
                                    dataItemKey="user_id"
                                    textField="display_name"
                                    required={true}
                                />
                                <br />
                                <DialogActionsBar>
                                    <Button
                                        className="ford-blue-btn"
                                        primary={true}
                                        type={'submit'}
                                        disabled={!formRenderProps.allowSubmit}
                                    >
                                        {localizationService.toLanguageString('custom.save', 'save')}
                                    </Button>
                                </DialogActionsBar>
                            </FormElement>
                        )}
                    />
                </Dialog>
            }
            {
                dialogDisplayed === 'denyPlan' &&
                <Dialog>
                    <h3>Reject Eligibility</h3>
                    <Form
                        onSubmit={rejectEligibility}
                        ignoreModified={true}
                        initialValues={{
                            ...formValues,
                        }}
                        render={(formRenderProps) => (
                            <FormElement horizontal={true}>
                                <p>Are you sure you want to DENY this event? The dealer will be notified immediately.</p>
                                {formError && <Error>{formError}</Error>}
                                <DialogActionsBar>
                                    <Button
                                        className="ford-blue-btn"
                                        primary={true}
                                        type={'submit'}
                                        disabled={!formRenderProps.allowSubmit}
                                    >
                                        {localizationService.toLanguageString('custom.reject_eligibility', 'reject_eligibility')}
                                    </Button>
                                    <Button
                                        className="ford-blue-btn"
                                        onClick={() => setDialogDisplayed('none')}>
                                        {localizationService.toLanguageString('custom.cancel', 'cancel')}
                                    </Button>
                                </DialogActionsBar>
                            </FormElement>
                        )}
                    />
                </Dialog>
            }
            {
                dialogDisplayed === 'rejectPlan' &&
                <Dialog>
                    <h3>Reject Plan</h3>
                    <Form
                        onSubmit={onRejectPlan}
                        initialValues={{
                            ...formValues,
                        }}
                        render={(formRenderProps) => (
                            <FormElement horizontal={true}>
                                <p>Are you sure you want to DENY this event? The dealer will be notified immediately.</p>
                                {formError && <Error>{formError}</Error>}
                                <Field
                                    component={TextArea}
                                    id={'reject_message'}
                                    name={'reject_message'}
                                    label={localizationService.toLanguageString('custom.reject_plan_reason', 'reject_plan_reason')}
                                    required={true}
                                />
                                <DialogActionsBar>
                                    <Button
                                        className="ford-blue-btn"
                                        primary={true}
                                        type={'submit'}
                                        disabled={!formRenderProps.allowSubmit}
                                    >
                                        {localizationService.toLanguageString('custom.reject_plan', 'reject_plan')}
                                    </Button>
                                    <Button
                                        className="ford-blue-btn"
                                        onClick={() => setDialogDisplayed('none')}>
                                        {localizationService.toLanguageString('custom.cancel', 'cancel')}
                                    </Button>
                                </DialogActionsBar>
                            </FormElement>
                        )}
                    />
                </Dialog>
            }
            {
                dialogDisplayed === 'approvePlan' &&
                <Dialog>
                    <h3>Approve Event</h3>
                    <Form
                        onSubmit={onSubmitFinalApproval}
                        ignoreModified={true}
                        initialValues={{
                            ...formValues,
                        }}
                        render={(formRenderProps) => (
                            <FormElement horizontal={true}>
                                <p>Are you sure you want to approve this event? The dealer will be notified immediately.</p>
                                {formError && <Error>{formError}</Error>}
                                <DialogActionsBar>
                                    <Button
                                        className="ford-blue-btn"
                                        primary={true}
                                        type={'submit'}
                                        disabled={!formRenderProps.allowSubmit}
                                    >
                                        {localizationService.toLanguageString('custom.approve_event', 'approve_event')}
                                    </Button>
                                    &nbsp;
                                    <Button
                                        className="ford-blue-btn"
                                        onClick={() => setDialogDisplayed('none')}>
                                        {localizationService.toLanguageString('custom.cancel', 'cancel')}
                                    </Button>
                                </DialogActionsBar>
                            </FormElement>
                        )}
                    />
                </Dialog>
            }
            {
                dialogDisplayed === 'none' &&
                <div className="card-container" style={{ maxWidth: 700 }}>
                    <h1 className="card-title" style={fontBold}>{localizationService.toLanguageString('custom.event_request', 'event_request')}</h1>

                    <div className="card-component">
                        <br />
                        <h3>
                            <span style={fontBold}>Status: </span>{capitalize(thisEvent?.status)}
                            {
                                // Eligibility buttons
                                login.user?.credentials.hasPermission('admin') && thisEvent?.status === 'new' &&
                                <p>
                                    <Button
                                        className="ford-blue-btn"
                                        onClick={() => setDialogDisplayed('approvePlan')}>Approve Eligibility</Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button
                                        className="ford-blue-btn"
                                        onClick={() => setDialogDisplayed('denyPlan')}>Reject Eligibility</Button>
                                </p>
                            }
                        </h3>
                        <hr />

                        {
                            formDisplayed === 'none' &&
                            <>
                                <h3 style={fontBold}>Dealer
                                    <Button
                                        className="ford-blue-btn"
                                        onClick={() => setFormDisplayed('dealer')}>View/Edit Dealer</Button></h3>
                                <p>
                                    <span style={fontSemiBold}>Name: </span>{dealer?.dealer_name}<br />
                                    <span style={fontSemiBold}>Location: </span>{dealer?.city}, {dealer?.state}<br />
                                    <span style={fontSemiBold}>Sales Code: </span>{dealer?.parts_code}<br />
                                    {/* TODO: format these */}
                                    <span style={fontSemiBold}>Notes: </span>{generateDealerNotes(dealer?.dealer_notes)}
                                </p>
                                <hr />
                            </>
                        }
                        {
                            formDisplayed === 'dealer' &&
                            <Form
                                onSubmit={onSubmitDealer}
                                initialValues={{
                                    ...formValues,
                                    ...dealer,
                                }}
                                render={(formRenderProps) => (
                                    <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                                        <h3>Edit Dealer</h3>
                                        {formError && <Error>{formError}</Error>}
                                        <Field
                                            id={'dealer_name'}
                                            name={'dealer_name'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.dealer_name', 'dealer_name')}
                                            validator={requiredValidator}
                                            component={Input}
                                            maxLength={50}
                                        />
                                        <Field
                                            id={'parts_code'}
                                            name={'parts_code'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.sales_code', 'sales_code')}
                                            validator={partsCodeValidator}
                                            component={Input}
                                            minLength={5}
                                            maxLength={5}
                                        />
                                        <Field
                                            id={'address'}
                                            name={'address'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.address', 'address')}
                                            validator={address1Validator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'address2'}
                                            name={'address2'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.address2', 'address2')}
                                            component={Input}
                                        />
                                        <Field
                                            id={'city'}
                                            name={'city'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.city', 'city')}
                                            validator={cityValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'state'}
                                            name={'state'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.state', 'state')}
                                            validator={stateValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'zip'}
                                            name={'zip'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.zip', 'zip')}
                                            validator={zipValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'phone'}
                                            name={'phone'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.dealershipPhone', 'dealershipPhone')}
                                            validator={phoneValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'primary_contact'}
                                            name={'primary_contact'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.primary_contact', 'primary_contact')}
                                            validator={requiredValidator}
                                            component={Input}
                                            maxLength={50}
                                        />
                                        <Field
                                            id={'contact_phone'}
                                            name={'contact_phone'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.contact_phone', 'contact_phone')}
                                            validator={phoneValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'contact_email'}
                                            name={'contact_email'}
                                            type={'email'}
                                            autoComplete="username"
                                            placeholder={'e.g.: peter@gmail.com'}
                                            label={localizationService.toLanguageString('custom.contactEmail', 'Email')}
                                            validator={emailValidator}
                                            required={true}
                                            component={Input}
                                        />
                                        <Field
                                            id={'password'}
                                            name={'password'}
                                            autoComplete="new-password"
                                            type={'password'}
                                            label={localizationService.toLanguageString('custom.password', 'Password')}
                                            validator={newPasswordValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'confirm_password'}
                                            name={'confirm_password'}
                                            type={'password'}
                                            autoComplete="new-password"
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.confirmPassword', 'Confirm Password')}
                                            validator={passwordMatchValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'new_dealer_note'}
                                            name={'new_dealer_note'}
                                            label={localizationService.toLanguageString('custom.dealerNote', 'New Dealer Note')}
                                            // validator={passwordMatchValidator}
                                            component={Input}
                                        />
                                        <hr />
                                        <div className={'card-buttons'}>
                                            <Button
                                                className="ford-blue-btn"
                                                type={'button'}
                                                onClick={() => setFormDisplayed('none')}
                                            >
                                                {localizationService.toLanguageString('custom.cancel', 'cancel')}
                                            </Button>

                                            &nbsp;&nbsp;&nbsp;&nbsp;

                                            <Button
                                                className="ford-blue-btn"
                                                primary={true}
                                                type={'submit'}
                                                disabled={!formRenderProps.allowSubmit}
                                            >
                                                {localizationService.toLanguageString('custom.update', 'update')}
                                            </Button>
                                        </div>
                                    </FormElement>
                                )}
                            />
                        }

                        {
                            formDisplayed === 'none' &&
                            <>
                                <h3 style={fontBold}>Organization
                                    <Button
                                        className="ford-blue-btn"
                                        onClick={() => setFormDisplayed('charity')}>View/Edit Organization
                                    </Button>
                                </h3>
                                <p>
                                    <span style={fontSemiBold}>Name: </span>{charity?.name}
                                    {/* <br />
                                    Tax ID: {charity?.tax_id} */}
                                </p>
                                <hr />
                            </>
                        }
                        {
                            formDisplayed === 'charity' &&
                            <Form
                                onSubmit={onSubmitCharity}
                                initialValues={{
                                    ...formValues,
                                    ...charity,
                                }}
                                render={(formRenderProps) => (
                                    <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                                        <h3>Edit Organization</h3>
                                        {formError && <Error>{formError}</Error>}
                                        <Field
                                            id={'name'}
                                            name={'name'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.charity_name', 'charity_name')}
                                            validator={requiredValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'address_1'}
                                            name={'address_1'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.address_1', 'address_1')}
                                            validator={requiredValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'address_2'}
                                            name={'address_2'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.address_2', 'address_2')}
                                            component={Input}
                                        />
                                        <Field
                                            id={'city'}
                                            name={'city'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.city', 'city')}
                                            validator={requiredValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'state'}
                                            name={'state'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.state', 'state')}
                                            validator={requiredValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'postal_code'}
                                            name={'postal_code'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.zip', 'zip')}
                                            validator={requiredValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'contact_first_name'}
                                            name={'contact_first_name'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.contact_first_name', 'contact_first_name')}
                                            validator={requiredValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'contact_last_name'}
                                            name={'contact_last_name'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.contact_last_name', 'contact_last_name')}
                                            validator={requiredValidator}
                                            component={Input}
                                        />
                                        <Field
                                            id={'contact_phone_number'}
                                            name={'contact_phone_number'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.contact_phone_number', 'contact_phone_number')}
                                            required={true}
                                            validator={phoneValidator}
                                            mask="000-000-0000"
                                            component={MaskedTextBox}
                                        />
                                        <Field
                                            id={'contact_email'}
                                            name={'contact_email'}
                                            type={'email'}
                                            placeholder={'e.g.: peter@gmail.com'}
                                            label={localizationService.toLanguageString('custom.contact_email', 'Email')}
                                            validator={emailValidator}
                                            required={true}
                                            component={Input}
                                        />
                                        <hr />
                                        <div className={'card-buttons'}>
                                            <Button
                                                className="ford-blue-btn"
                                                type={'button'}
                                                onClick={() => setFormDisplayed('none')}
                                            >
                                                {localizationService.toLanguageString('custom.cancel', 'cancel')}
                                            </Button>

                                            &nbsp;&nbsp;&nbsp;&nbsp;

                                            <Button
                                                className="ford-blue-btn"
                                                primary={true}
                                                type={'submit'}
                                                disabled={!formRenderProps.allowSubmit}
                                            >
                                                {localizationService.toLanguageString('custom.update', 'update')}
                                            </Button>
                                        </div>
                                    </FormElement>
                                )}
                            />
                        }

                        {
                            (formDisplayed === 'none' && thisEvent?.event_request_id) &&
                            <>
                                <h3 style={fontBold}>Eligibility Request</h3>
                                {/* <Button onClick={() => setFormDisplayed('eventRequest')}>View/Edit Request</Button> */}
                                <p>
                                    <span style={fontSemiBold}>Name: </span>{thisEvent?.event_name}<br />
                                    <span style={fontSemiBold}>Organization: </span>{thisEvent?.charity_partner}<br />
                                    <span style={fontSemiBold}>Requested Date: </span>{thisEvent?.requested_date_formatted}<br />
                                    <span style={fontSemiBold}>Funding Purpose: </span>{thisEvent?.funding_purpose}<br />
                                    <br />
                                    <span style={fontSemiBold}>Event Location: </span>{thisEvent?.event_location_type}<br />
                                    <span style={fontSemiBold}>Event Address: </span>{generateEventAddress()}<br />
                                    <span style={fontSemiBold}>Check Payable: </span>{thisEvent?.check_payable}<br />
                                    <span style={fontSemiBold}>Kit Location: </span>{thisEvent?.kit_location}<br />
                                    {/* Additional Schools: {thisEvent?.additional_schools}<br /> */}
                                    <span style={fontSemiBold}>Secondary Organization: </span>{thisEvent?.secondary_charity || 'N/A'}<br />
                                </p>
                            </>
                        }

                        {formDisplayed === 'eventRequest' &&
                            <Form
                                onSubmit={onSubmitEligibility}
                                initialValues={{
                                    ...formValues,
                                    ...thisEvent,
                                    assigned_planner: assignedPlanner,
                                    requested_month: thisEvent?.requested_month,
                                    requested_date: thisEvent?.requested_date,
                                }}
                                render={(formRenderProps) => (
                                    <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                                        <h3>Edit Eligibility Request</h3>
                                        {formError && <Error>{formError}</Error>}
                                        <Field
                                            component={DropDownList}
                                            id={'assigned_planner'}
                                            name={'assigned_planner'}
                                            label={localizationService.toLanguageString('custom.assigned_planner', 'assigned_planner')}
                                            data={planners}
                                            dataItemKey="user_id"
                                            textField="display_name"
                                            required={true}
                                        />
                                        <Field
                                            id={'attendance'}
                                            name={'attendance'}
                                            childRef={attendanceRef}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.attendance', 'attendance')}
                                            validator={requiredValidator}
                                            component={NumericTextBox}
                                        />
                                        <Field
                                            id={'requested_month'}
                                            name={'requested_month'}
                                            // type={'input'}
                                            label={localizationService.toLanguageString('custom.requested_month', 'requested_month')}
                                            validator={requiredValidator}
                                            data={months}
                                            component={DropDownList}
                                        />
                                        <Field
                                            id={'requested_date'}
                                            name={'requested_date'}
                                            childRef={requestedDateRef}
                                            label={localizationService.toLanguageString('custom.requested_date', 'requested_date')}
                                            format="MM/dd/yyyy"
                                            // no minimum for admins
                                            // min={moment.max(moment('2022-03-01'), moment().add(60,'days')).toDate()}
                                            min={moment().toDate()}
                                            max={moment('2022-11-30').toDate()}
                                            component={DatePicker}
                                        />
                                        <hr />
                                        <div className={'k-form-buttons'}>
                                            <Button
                                                type={'button'}
                                                onClick={() => setFormDisplayed('none')}
                                            >
                                                {localizationService.toLanguageString('custom.cancel', 'cancel')}
                                            </Button>

                                            &nbsp;&nbsp;&nbsp;&nbsp;

                                            <Button
                                                primary={true}
                                                type={'submit'}
                                                disabled={!formRenderProps.allowSubmit}
                                            >
                                                {localizationService.toLanguageString('custom.update', 'update')}
                                            </Button>
                                        </div>
                                    </FormElement>
                                )}
                            />
                        }

                    </div>
                </div>
            }
        </div>
    );
}

export default EventPlanningFormScreen;
