import { observer } from "mobx-react-lite";
import { useLocalization } from "@progress/kendo-react-intl";
import { useStores } from "src/stores";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { useEffect, useState, useRef } from "react";
import { useServices } from "src/services";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridToolbar
} from "@progress/kendo-react-grid";
import { Button, ToolbarSpacer } from "@progress/kendo-react-buttons";
import { useHistory } from "react-router";
import { DataResult, process, State } from "@progress/kendo-data-query";
import { D4URxEvent } from "src/utils/classes";
import {
  ColumnMenu,
  ColumnMenuCheckboxFilter,
} from "src/components/GridColumnMenus";
import multiColumnSort from "multi-column-sort";
import moment from 'moment-timezone';

const EventsScreen = () => {
  const localizationService = useLocalization();

  const _export = useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const _exportRDA = useRef<ExcelExport | null>(null);
  // const excelExportRDA = () => {
  //   if (_exportRDA.current !== null) {
  //     _exportRDA.current.save();
  //   }
  // };

  const { ui, login } = useStores();
  const { d4ur } = useServices();
  let history = useHistory();

  const sortPresets: { [key: string]: State; } = {
    clear: {
      sort: [
        {
          field: 'sort_date',
          dir: 'asc',
        },
      ]
    },
    default: {
      filter: {
        logic: "and", filters: [
          {
            logic: "and", filters: [
              { field: "sort_date", operator: "gte", value: moment().toDate() },
            ]
          },
          {
            logic: "or", filters: [
              { field: "status_pretty", operator: "eq", value: "New" },
              { field: "status_pretty", operator: "eq", value: "Eligible" },
              { field: "status_pretty", operator: "eq", value: "Planning" },
              { field: "status_pretty", operator: "eq", value: "Revise" },
              { field: "status_pretty", operator: "eq", value: "Review" },
              { field: "status_pretty", operator: "eq", value: "Approved" },
              { field: "status_pretty", operator: "eq", value: "Event Ending" },
              { field: "status_pretty", operator: "eq", value: "Event Ended" },
              { field: "status_pretty", operator: "eq", value: "Donation Requested" },
              { field: "status_pretty", operator: "eq", value: "Donation Approved" },
              { field: "status_pretty", operator: "eq", value: "Check Approved" },
              { field: "status_pretty", operator: "eq", value: "Reimbursement Requested" },
              { field: "status_pretty", operator: "eq", value: "Reimbursement Approved" },
            ]
          }
        ]
      },
      sort: [
        {
          field: 'status_pretty',
          dir: 'asc',
        },
        {
          field: 'sort_date',
          dir: 'asc',
        },
      ]
    },
    action: {
      filter: {
        logic: "and", filters: [
          // {logic: "and", filters: [
          //     {field: "sort_date", operator: "gte", value: moment().toDate()},
          // ]},
          {
            logic: "or", filters: [
              { field: "status_pretty", operator: "eq", value: "New" },
              { field: "status_pretty", operator: "eq", value: "Review" },
              { field: "status_pretty", operator: "eq", value: "Reimbursement Submitted" },
              { field: "status_pretty", operator: "eq", value: "Donation Requested" },
              { field: "status_pretty", operator: "eq", value: "Event Ended" },
            ]
          }
        ]
      },
      sort: [
        {
          field: 'status_pretty',
          dir: 'asc',
        },
        {
          field: 'sort_date',
          dir: 'asc',
        },
      ]
    },
    waiting: {
      filter: {
        logic: "and", filters: [
          // {logic: "and", filters: [
          //     {field: "sort_date", operator: "gte", value: moment().toDate()},
          // ]},
          {
            logic: "or", filters: [
              { field: "status_pretty", operator: "eq", value: "Eligible" },
              { field: "status_pretty", operator: "eq", value: "Planning" },
              { field: "status_pretty", operator: "eq", value: "Revise" },
              { field: "status_pretty", operator: "eq", value: "Donation Approved" },
            ]
          }
        ]
      },
      sort: [
        {
          field: 'status_pretty',
          dir: 'asc',
        },
        {
          field: 'sort_date',
          dir: 'asc',
        },
      ]
    },
    yesterday: {
      filter: {
        logic: "and", filters: [{
          logic: "and", filters: [
            { field: "sort_date", operator: "eq", value: moment().subtract(1, 'days').startOf('day').toDate() },
          ]
        }]
      },
      sort: [
        {
          field: 'dealer_name',
          dir: 'asc',
        },
      ]
    },
    today: {
      filter: {
        logic: "and", filters: [{
          logic: "and", filters: [
            { field: "sort_date", operator: "eq", value: moment().startOf('day').toDate() },
          ]
        }]
      },
      sort: [
        {
          field: 'dealer_name',
          dir: 'asc',
        },
      ]
    },
    tomorrow: {
      filter: { logic: "and", filters: [{ logic: "and", filters: [{ field: "sort_date", operator: "eq", value: moment().add(1, 'days').startOf('day').toDate() }] }] },
      sort: [
        {
          field: 'dealer_name',
          dir: 'asc',
        },
      ]
    },
    thisWeek: {
      filter: {
        logic: "and", filters: [{
          logic: "and", filters: [
            { field: "sort_date", operator: "gte", value: moment().startOf('isoWeek').startOf('day').toDate() },
            { field: "sort_date", operator: "lte", value: moment().endOf('isoWeek').startOf('day').toDate() },
          ]
        }]
      },
      sort: [
        {
          field: 'sort_date',
          dir: 'asc',
        },
        {
          field: 'dealer_name',
          dir: 'asc',
        },
      ]
    },
    nextWeek: {
      filter: {
        logic: "and", filters: [{
          logic: "and", filters: [
            { field: "sort_date", operator: "gte", value: moment().add(1, 'weeks').startOf('isoWeek').startOf('day').toDate() },
            { field: "sort_date", operator: "lte", value: moment().add(1, 'weeks').endOf('isoWeek').startOf('day').toDate() },
          ]
        }]
      },
      sort: [
        {
          field: 'sort_date',
          dir: 'asc',
        },
        {
          field: 'dealer_name',
          dir: 'asc',
        },
      ]
    },
    lastWeek: {
      filter: {
        logic: "and", filters: [{
          logic: "and", filters: [
            { field: "sort_date", operator: "gte", value: moment().subtract(1, 'weeks').startOf('isoWeek').startOf('day').toDate() },
            { field: "sort_date", operator: "lte", value: moment().subtract(1, 'weeks').endOf('isoWeek').startOf('day').toDate() },
          ]
        }]
      },
      sort: [
        {
          field: 'sort_date',
          dir: 'asc',
        },
        {
          field: 'dealer_name',
          dir: 'asc',
        },
      ]
    },
    blueCruise: {
      filter: {
        logic: "and", filters: [{
          logic: "and", filters: [
            { field: "bonus_event", operator: "eq", value: 1 },
          ]
        }]
      },
      sort: [
        {
          field: 'sort_date',
          dir: 'asc',
        },
      ]
    },
    past: {
      filter: {
        logic: "and", filters: [
          {
            logic: "and", filters: [
              { logic: "and", filters: [{ field: "sort_date", operator: "lt", value: moment().toDate() }] },
            ]
          },
        ]
      },
      sort: [
        {
          field: 'status_pretty',
          dir: 'asc',
        },
        {
          field: 'sort_date',
          dir: 'desc',
        },
      ]
    },
  }

  const [dataState, setDataState] = useState<State>(sortPresets.default);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');


  type Direction = "ASC" | "DESC";
  type SortObject<T> = {
    [key in keyof T]?: Direction;
  };

  const customProcess = (data: any[], state: State) => {
    const preProcessed = process(data, state).data;
    // custom sort
    const getColumnValues = (column: any, value: any) => {
      switch (column) {
        // case 'sort_status':
        //     return value.data.value;
        default:
          return value;
      }
    }

    const sortOrder: SortObject<any> = {};

    for (const s of (state.sort || [])) {
      let fieldToSort = s.field;

      switch (fieldToSort) {
        case 'status_pretty':
          fieldToSort = 'sort_status';
          break;
      }

      sortOrder[fieldToSort] = s.dir?.toUpperCase() as Direction;
    };

    let newData = [];
    if (state.sort?.length) {
      newData = multiColumnSort(
        preProcessed,
        sortOrder,
        getColumnValues
      );
    } else {
      newData = preProcessed;
    }

    return {
      data: newData,
      total: preProcessed.length,
    } as DataResult;
  }


  const processEvents = (events: D4URxEvent[]): D4URxEvent[] => {
    return events.map((e) => {
      return new D4URxEvent(e);
    });
  };

  // const [planners, setPlanners] = useState<UserDetails[]>([]);
  const [eventList, setEventList] = useState<D4URxEvent[]>([]);
  const [dataResult, setDataResult] = useState<DataResult>(
    customProcess(eventList, dataState)
  );
  const [rdaData, setRDAData] = useState<DataResult>();

  const loadEvents = () => {
    ui.setIsGettingEvents(true);
    d4ur.getEvents(ui.currentSeason.season_id).then((ret) => {
      // TODO: make season dynamic
      ui.setIsGettingEvents(false);

      const processedEvents = processEvents(
        (ret as D4URxFetchResult).result || []
      );

      setEventList(processedEvents);
      setDataResult(customProcess(processedEvents, dataState));
      const rdaTemp = processedEvents.filter((e) => e.event_id && !e.cancelled);
      setRDAData({
        data: rdaTemp,
        total: rdaTemp.length,
      });
    });
  }

  useEffect(() => {
    // ui.setIsGettingEvents(true);
    // d4ur.getEvents(8).then((ret) => {
    //   // TODO: make season dynamic
    //   ui.setIsGettingEvents(false);

    //   const processedEvents = processEvents(
    //     (ret as D4URxFetchResult).result || []
    //   );

    //   setEventList(processedEvents);
    //   setDataResult(customProcess(processedEvents, dataState));
    //   const rdaTemp = processedEvents.filter((e) => e.event_id && !e.cancelled);
    //   setRDAData({
    //     data: rdaTemp,
    //     total: rdaTemp.length,
    //   });
    // });
    loadEvents();

    // d4ur.getPlanners().then((plannersResult) => {
    //   setPlanners((plannersResult as D4URxFetchResult).result as UserDetails[]);
    // })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const EventActionsCell = (props: GridCellProps) => {
    const { event_id, event_request_id } = new D4URxEvent(props.dataItem);

    return (
      <td>
        <Button
          className="k-button k-primary"
          // onClick={() => props.enterEdit(props.dataItem)}
          onClick={() => {
            if (event_id) {
              history.push(`/event/${event_id}`);
            } else {
              history.push(`/eventRequest/${event_request_id}`);
            }
          }}
        >
          View / Edit
        </Button>
      </td>
    );
  };

  const BlueCruiseCell = (props: GridCellProps) => {
    const { event_id, bonus_event } = new D4URxEvent(props.dataItem);
    return (
      <td>
        <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "auto",
          }}>
          <label>Blue Cruise Event</label>
          <input
            type="checkbox"
            checked={bonus_event}
            style={{ width: "30px", height: "30px", cursor: "pointer" }}
            onChange={(e) => {
              d4ur.setBlueCruiseStatus(event_id, e.target.checked).then((ret) => {
                if (ret.response.status === 200) {
                  setIsLoading(false);
                  setSuccessMessage('Successfully updated bonus event status.')
                  loadEvents();
                  setTimeout(() => {
                    setSuccessMessage('');
                  }, 2500);
                } else {
                  setErrorMessage(ret.result.message)
                  setIsLoading(false);
                }
              })
                .catch(e => console.log('UPDATE EVENT ERROR', e))
            }}
          />
        </div>
      </td>
    );
  };

  const DealerNameCell = (props: GridCellProps) => {
    const { dealer_name, dealer_notes, event_id, event_request_id } = new D4URxEvent(props.dataItem);
    return (
      <td>
        {dealer_name}
        {dealer_notes && (<span
          key={dealer_name + 'notes'}
          className={'k-icon k-i-file-txt'}
          style={{ color: '#ffa600', margin: 10 }}
          onClick={() => {
            if (event_id) {
              history.push(`/event/${event_id}`);
            } else {
              history.push(`/eventRequest/${event_request_id}`);
            }
          }}
        />)}
      </td>
    );
  };

  const TrainingStatusCell = (props: GridCellProps) => {
    const sendReminder = () => {
      // TODO: refresh events list after sending reminder to update column. calling loadEvents() isnt doing it. 
      d4ur.triggerEmail('pre_event', props.dataItem.event_id).then((ret: any) => {
        if (ret.response.status === 200) {
          setIsLoading(false);
          setSuccessMessage('Successfully sent emails!')
        } else {
          setErrorMessage(ret.result.message)
          setIsLoading(false);
        }
      })
        .catch(e => console.log('TRIGGER EMAIL ERROR', e))
    }
    const getLastSentAt = (emailsSent: Array<emailsSent>) => {
      const parseDate = (email: emailsSent) => new Date(email.sent_at);
      emailsSent.sort((a, b) => parseDate(b).getTime() - parseDate(a).getTime());
      const mostRecentEmail = emailsSent[0];

      return `${moment(mostRecentEmail.sent_at).tz('America/New_York').format('MMMM Do, h:mm a')} (EST) to ${mostRecentEmail.sent_to.length > 1 ? mostRecentEmail.sent_to.join(', ') : mostRecentEmail.sent_to}`;
    }
    const trainingStatus: string = props.dataItem.training_status;
    const trainingStatusPretty: string = props.dataItem.training_status_pretty;
    if (trainingStatus === "due") {
      return (
        <td>
          <p style={{ fontSize: '14px' }}>{trainingStatusPretty}</p>
          <Button
            className="k-button k-primary"
            onClick={() => { sendReminder() }}
          >
            Send Reminder Email
          </Button>
        </td>
      )
    } else if (trainingStatus === "reminder sent") {
      return (
        <td>
          <p style={{ fontSize: '14px' }}>{trainingStatusPretty}</p>
          <p style={{ fontSize: '12px' }}>Last Reminder Sent At: {getLastSentAt(props.dataItem.formatted_custom_data.training_reminder_sent)}</p>
          <Button
            className="k-button k-primary"
            onClick={() => { sendReminder() }}
          >
            Re-send Reminder
          </Button>
        </td>
      )
    } else if (trainingStatus === "completed") {

      return (
        <td>
          <p style={{ fontSize: '14px' }}>Completed ({props.dataItem.training_completed_by})</p>
        </td>
      )
    } else {
      return (
        <td>
          <p style={{ fontSize: '14px' }}>{trainingStatusPretty}</p>
        </td>
      )
    }
  };

  const doDataState = (state: State) => {
    console.log('event.dataState', state);
    setDataResult(customProcess(eventList, state));
    setDataState(state);
  }

  const rowRender = (row, props) => {
    // if blue cruise event, make row blue
    let color = "#fff";
    let fontColor = 'black';
    if (props.dataItem.bonus_event) {
      color = "#00095B";
      fontColor = 'white';
    }
    let style = {
      ...row.props.style,
      backgroundColor: color,
      borderColor: "black",
      borderWidth: 2,
      color: fontColor,
    };
    return <tr {...row.props} style={style}></tr>;
  };

  // const plannersCell = (props: GridCellProps) => {
  //   const { display_name, eligibility_custom_data } = new D4URxEvent(props.dataItem);
  //   const assignedPlannerID = JSON.parse(eligibility_custom_data || '').assigned_planner
  //   if (display_name) {
  //     return (
  //       <td>
  //         <p>{display_name}</p>
  //       </td>
  //     )
  //   }
  //   else if (assignedPlannerID && planners.length) {
  //     return (
  //       <td>
  //         <p>{planners?.find((x) => x.user_id === assignedPlannerID)?.display_name}</p>
  //       </td>
  //     )
  //   } else {
  //     return (
  //       <td>
  //       </td>
  //     )
  //   }
  // };


  const approvedStatuses = ['approved', 'event ended', 'event ending', 'in progress', 'donation requested', 'donation approved', 'reimbursement submitted', 'reimbursement requested', 'reimbursement approved'];

  const editExportData = (data: any) => {
    return data.map((d: any) => {
      return {
        ...d,
        bonus_event: d.bonus_event ? 'YES' : null,
      }
    })
  }


  return (
    <div id="Dashboard" className="dashboard-page main-content">
      <div className="card-container grid">
        <h3 className="card-title">
          {login.user?.credentials.display_name}:{" "}
          {localizationService.toLanguageString("custom.events", "events")}
          <p>Total Event Requests: {eventList.length}</p>
          <p>Total Events Approved: {eventList.filter(event => approvedStatuses.includes(event.status as D4URxEventStatus)).length}</p>
        </h3>
        {/* // TODO: going to replace "sending..." with real spinner during styling updates. same on Event page.  */}
        {isLoading ? <p style={{ color: 'red' }}>Sending...</p> : null}
        {errorMessage ? <p style={{ color: 'red' }}>{errorMessage}</p> : null}
        {successMessage ? <p style={{ color: 'green' }}>{successMessage}</p> : null}
        <div className="card-component">
          <ExcelExport data={editExportData(dataResult.data)} ref={_export} fileName={`events_export_${moment().format('YYYYMMDD-hhmma')}.xlsx`}>
            <ExcelExportColumn field="event_request_id" title="Request ID" />
            <ExcelExportColumn field="event_id" title="Latitude Event ID" />
            <ExcelExportColumn field="ffs_event_id" title="FFS Event ID" />
            {/* <ExcelExportColumn field="event_type_display" title="Event Type" /> */}
            <ExcelExportColumn field="bonus_event" title="Blue Cruise Event"/>
            {/* <ExcelExportColumn field="event_name" title="Event Name" /> */}
            <ExcelExportColumn field="dealer_name" title={localizationService.toLanguageString('custom.dealer', 'dealer')} />
            <ExcelExportColumn field="parts_code" title="Parts Code" />
            <ExcelExportColumn field="region" title="Dealer Region" />
            <ExcelExportColumn field="market_area" title="Dealer Market" />
            <ExcelExportColumn field="charity_partner" title="Organization" />
            {/* <ExcelExportColumn field="display_name" title="Planner" /> */}
            <ExcelExportColumn field="start_date" title="Event Date" />
            {/* <ExcelExportColumn field="start_time" title="Start Time" />
            <ExcelExportColumn field="end_time" title="End Time" /> */}
            {/* <ExcelExportColumn field="time_zone" title="Time Zone" /> */}
            <ExcelExportColumn field="status" title="Status" />
            <ExcelExportColumn field="check_amount" title="Check Amount" headerCellOptions={{ textAlign: 'center' }} />
            <ExcelExportColumn 
              field="total_pre_drives"
              title="Pre-Drives"
              headerCellOptions={{ textAlign: 'center' }}
            />
            <ExcelExportColumn 
              field="total_post_drives"
              title="Post-Drive Surveys"
              headerCellOptions={{ textAlign: 'center' }}
            />
            <ExcelExportColumn 
              field="total_bonus_drives"
              title="Bonus Drive Surveys"
              headerCellOptions={{ textAlign: 'center' }}
            />
            <ExcelExportColumn 
              field="valid_post_count"
              title="Valid Post-Drive Surveys"
              headerCellOptions={{ textAlign: 'center' }}
            />
            <ExcelExportColumn
              field="bonus_valid_count"
              title="Valid Bonus Drive Surveys"
              headerCellOptions={{ textAlign: 'center' }}
            />
          </ExcelExport>
          <ExcelExport
            data={rdaData?.data}
            fileName={`IPSOS_events_export_${moment().format('YYYYMMDD-hhmma')}.xlsx`}
            ref={_exportRDA}
          >
            <ExcelExportColumn field="ffs_ford_campaign" title="Campaign ID" />
            <ExcelExportColumn field="ffs_ford_sequence" title="Sequence #" />
            <ExcelExportColumn field="ffs_event_id" title="Event ID" />
            <ExcelExportColumn field="start_date_export" title="Start Date" />
            <ExcelExportColumn field="end_date_export" title="End Date" />
            <ExcelExportColumn field="event_name" title="Event" />
            <ExcelExportColumn field="event_name" title="Event name for survey (i.e., consumer friendly)" />
            <ExcelExportColumn field="charity_partner" title="Event Partner Name (enter n/a if not applicable)" />
            <ExcelExportColumn field="address" title="Event Street Address" />
            <ExcelExportColumn field="city" title="Event City" />
            <ExcelExportColumn field="state" title="Event State" />
            <ExcelExportColumn field="zip_code" title="Event Zip Code" />

            <ExcelExportColumn field="market_area" title="Market" />
            <ExcelExportColumn field="audience_type" title="Audience Type" />
            <ExcelExportColumn field="category" title="Event Category" />
            <ExcelExportColumn field="language_option" title="Language Option" />
            <ExcelExportColumn field="attendance" title="Attendance" />
            <ExcelExportColumn field="indoor_outdoor" title="Event Indoor/Outdoor" />
            <ExcelExportColumn field="test_drive" title="Test Drive" />
            <ExcelExportColumn field="regional" title="Regional/National Program" />
            <ExcelExportColumn field="lincoln_event_partner" title="Lincoln Event Partner" />
            <ExcelExportColumn field="audience_type_full" title="Audience Type" />
            <ExcelExportColumn field="category_long" title="Event Category" />
            <ExcelExportColumn field="test_drive_full" title="Test Drive" />
            <ExcelExportColumn field="regional_full" title="Regional/National Program" />
          </ExcelExport>
          <Grid
            data={dataResult}
            rowRender={rowRender}
            sortable={{ mode: "multiple" }}
            {...dataState}
            onDataStateChange={(event: GridDataStateChangeEvent) => {
              console.log("event.dataState", event.dataState);
              setDataResult(customProcess(eventList, event.dataState));
              setDataState(event.dataState);
            }}
            resizable={true}
          >

            <GridToolbar>
              Quick Views:
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.default)}>Default</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.action)}>Action Needed</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.waiting)}>Waiting on Dealer</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.today)}>Today's Events</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.tomorrow)}>Tomorrow</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.thisWeek)}>This Week</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.nextWeek)}>Next Week</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.yesterday)}>Yesterday</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.lastWeek)}>Last Week</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.blueCruise)}>Blue Cruise</button>
              {/* <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.clear)}>Clear All</button> */}
              {/* <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={excelExport}>Export</button> */}
              {/* <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={excelExportRDA}>IPSOS Export</button> */}
            </GridToolbar>
            <GridToolbar>
              <button className="k-button k-button-md k-rounded-md " onClick={() => doDataState(sortPresets.clear)}>Clear All</button>
              <button className="k-button k-button-md k-rounded-md" onClick={excelExport}>Export</button>
            </GridToolbar>
            {/* <GridColumn
              field="event_name"
              title="Event Name"
              columnMenu={ColumnMenu}
            /> */}
            <GridColumn
              field="dealer_name"
              title={localizationService.toLanguageString(
                "custom.dealer",
                "dealer"
              )}
              columnMenu={ColumnMenu}
              cell={DealerNameCell}
              width={200}
            />
            <GridColumn cell={BlueCruiseCell} width={250} />
            <GridColumn
              field="charity_partner"
              title="Organization"
              columnMenu={ColumnMenu}
              width={200}
            />
            {/* <GridColumn
              title="Planner"
              cell={plannersCell}
              columnMenu={ColumnMenu}
            /> */}
            <GridColumn
              field="sort_date"
              title="Event Date"
              format="{0:E, MMM dd yyyy}"
              filter="date"
              columnMenu={ColumnMenu}
              width={200}
            />
            <GridColumn
              field="status_pretty"
              title="Status"
              columnMenu={ColumnMenuCheckboxFilter(eventList, {
                searchBox: () => null,
              })}
              width={200}
            />
            <GridColumn
              cell={TrainingStatusCell}
              field="training_status_pretty"
              title="Training Status"
              columnMenu={ColumnMenuCheckboxFilter(eventList, {
                searchBox: () => null,
              })}
              width={200}
            />
            <GridColumn
              field="check_amount_pretty"
              title="Check"
              width={100}
            />
            <GridColumn cell={EventActionsCell} width={200} />
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default observer(EventsScreen);
