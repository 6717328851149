import React, { useState } from 'react';

import { useLocalization } from '@progress/kendo-react-intl';
import { useHistory } from "react-router-dom";
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Error } from "@progress/kendo-react-labels";
import { emailValidator } from '../validators';

import { AppContext } from '../AppContext'
import { useServices } from 'src/services';
import { Input } from 'src/components/Form/Input';

const ForgotPasswordScreen = () => {
    const { ...formValues } = React.useContext(AppContext);
    const localizationService = useLocalization();
    const history = useHistory();
    const [passwordError, setPasswordError] = useState<string>();
    const [confirmMessage, setConfirmMessage] = useState<string>('');
    const { d4ur } = useServices();



    const onSubmit = React.useCallback(
        async (dataItem) => {
            const call: any = await d4ur.doForgotPassword(dataItem.contact_email);
            if (call.result.error) {
                console.log('[ForgotPasswordScreen]', 'Password Error');
                setPasswordError(call.result.error);
            }
            if (call.result.success) {
                console.log('[ForgotPasswordScreen]', 'email sent');
                setConfirmMessage(localizationService.toLanguageString('custom.confirmPasswordMessage', 'Please check your email'))
            }
        },
        [d4ur, localizationService]
    );

    return (
        <div id="Login" className="login-page">
            <div className="card-container" style={{ maxWidth: 700 }}>
                <div className="card-component">
                    <Form
                        onSubmit={onSubmit}
                        initialValues={{
                            ...formValues,
                            contact_email: ''
                        }}
                        render={(formRenderProps) => (
                            <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                                {passwordError && <Error>{passwordError}</Error>}

                                <Field
                                    id={'contact_email'}
                                    name={'contact_email'}
                                    type={'email'}
                                    autoComplete="username"
                                    placeholder={'e.g.: peter@gmail.com'}
                                    label={localizationService.toLanguageString('custom.contactEmail', 'Email')}
                                    validator={emailValidator}
                                    required={true}
                                    component={Input}
                                />

                                <hr />
                                <div className={'k-form-buttons'}>
                                    <p>{confirmMessage && confirmMessage}</p>
                                    <Button
                                        primary={true}
                                        type={'submit'}
                                        disabled={!formRenderProps.allowSubmit}
                                        hidden={!!confirmMessage}
                                    >
                                        {localizationService.toLanguageString('custom.submit', 'Submit')}
                                    </Button>
                                </div>
                                <Button
                                    primary={true}
                                    onClick={() => {
                                        history.replace("/");
                                    }}
                                    hidden={!confirmMessage}
                                >
                                    {localizationService.toLanguageString('custom.goBack', 'Go Back')}
                                </Button>
                            </FormElement>
                        )}
                    />
                </div>
            </div>
        </div>
    );
}

export default ForgotPasswordScreen;
