import { observable, action, makeObservable } from 'mobx';
import { persist } from 'mobx-persist';

import { HydratedStore, User } from 'src/utils/classes';

class LoginStore extends HydratedStore {
  constructor() {
    super('LoginStore_v2'); // Storage ID

    makeObservable(this);
  }

  @persist @observable loginEmail: string = '';
  @observable loginPassword: string = '';
  @persist('object', User) @observable user?: User;
  @persist @observable userToken: string = '';

  @action setLoginEmail = (value: string) => this.loginEmail = value;
  @action setLoginPassword = (value: string) => this.loginPassword = value;
  @action setUser = (value?: any) => this.user = value ? new User(value) : undefined;
  @action setUserToken = (value: string) => this.userToken = value;
  @action doLogout = () => {
    this.userToken = '';
    this.user = undefined;
    this.loginPassword = '';
  };
}

export default new LoginStore();
