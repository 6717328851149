import React from "react";
import * as PropTypes from "prop-types";

import { DatePicker as KendoDatePicker } from "@progress/kendo-react-dateinputs";
import { FieldWrapper } from "@progress/kendo-react-form";
import { Label, Error, Hint } from "@progress/kendo-react-labels";

export const DatePicker = (fieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    childRef,
    ...others
  } = fieldRenderProps;

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";

  return (
    <FieldWrapper style={{ marginBottom: 10 }}>
      <div className={"k-form-field-wrap"}>
        <Label
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}
        // style={{ position: "absolute", top: "-20px" }}
        >
          {label}
        </Label>
        <KendoDatePicker
          valid={valid}
          type={type}
          ref={childRef}
          id={id}
          disabled={disabled}
          ariaDescribedBy={`${hintId} ${errorId}`}
          width={150}
          {...others}
        />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && (
          <Error id={errorId}>{validationMessage}</Error>
        )}
      </div>
    </FieldWrapper>
  );
};

DatePicker.displayName = "DatePicker";
DatePicker.propTypes = {
  valid: PropTypes.bool,
  value: PropTypes.string,
  id: PropTypes.string,
  optional: PropTypes.bool,
  label: PropTypes.string,
  hint: PropTypes.string,
  validationMessage: PropTypes.string,
  visited: PropTypes.bool,
};
