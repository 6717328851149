import React, { useEffect, useState } from 'react';

import { useLocalization } from '@progress/kendo-react-intl';
import { useHistory, useLocation } from "react-router-dom";
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Error } from "@progress/kendo-react-labels";
import { emailValidator, requiredValidator } from '../validators';

import { AppContext } from './../AppContext'
import { useStores } from 'src/stores';
import { useServices } from 'src/services';
import { Input } from 'src/components/Form/Input';
import { User } from 'src/utils/classes';

import fordBlueLogo from "../assets/ford-blue.png";

const LoginScreen = () => {
    const { ...formValues } = React.useContext(AppContext);
    const localizationService = useLocalization();
    const history = useHistory();

    const [loginError, setLoginError] = useState<string>();
    const { login } = useStores();
    const { d4ur } = useServices();

    let location = useLocation<Location>();

    let { from } = location.state as any || { from: { pathname: "/events" } };

    useEffect(() => {
        if (login.user && login.user?.credentials.user_role !== 'rrd') {
            console.log('[LoginScreen]', 'I think we are already logged in');
            history.replace('/events');
        } else if (login.user && login.user?.credentials.user_role === 'rrd') {
            console.log('[LoginScreen] RRD User', 'I think we are already logged in');
            history.replace('/kit_delivery');
        }
        // eslint-disable-next-line
    }, [])

    const onSubmit = React.useCallback(
        async (dataItem) => {
            login.setLoginEmail(dataItem.email);
            const call: any = await d4ur.doLogin(dataItem.email, dataItem.password);
            if (call.result.ERROR) {
                console.log('[LoginScreen]', 'Login Error');
                return setLoginError(call.result.message);
            }
            const thisUser = new User(call.result);
            console.log('[LoginScreen]', 'logged in, no 2fa required');
            login.setUserToken(thisUser.token)
            login.setUser(thisUser);
            if (thisUser.credentials.user_role === 'rrd') {
                from = { pathname: "/kit_delivery" };
            }
            history.replace(from);
        },
        [login, from, history, d4ur]
    );

    return (
        <div id="Login" className="login-page">
            <img src={fordBlueLogo} alt="ford" />
            <h1>Log In</h1>
            <Form
                onSubmit={onSubmit}
                initialValues={{
                    ...formValues,
                    email: login.loginEmail,
                }}
                render={(formRenderProps) => (
                    <FormElement className="k-form" horizontal={false}>
                        {loginError && <Error>{loginError}</Error>}
                        <Field
                            id={'email'}
                            name={'email'}
                            type={'email'}
                            placeholder={'e.g.: peter@gmail.com'}
                            label={localizationService.toLanguageString('custom.email', 'Email')}
                            validator={emailValidator}
                            required={true}
                            component={Input}
                        />
                        <Field
                            id={'password'}
                            name={'password'}
                            type={'password'}
                            label={localizationService.toLanguageString('custom.password', 'Password')}
                            validator={requiredValidator}
                            component={Input}
                        />
                        <div className={'btn-group'}>
                            <Button
                                className="ford-blue-btn"
                                primary={true}
                                type={'submit'}
                                disabled={!formRenderProps.allowSubmit}
                            >
                                {localizationService.toLanguageString('custom.logIn', 'Log In')}
                            </Button>
                            <a
                                onClick={() => {
                                    history.replace("/password");
                                }}
                            >
                                {localizationService.toLanguageString("custom.forgotPassword", 'Forgot password?')}
                            </a>
                        </div>
                    </FormElement>
                )}
            />
        </div>
    );
}

export default LoginScreen;
