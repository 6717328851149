import { stores } from 'src/stores';
import { Dealer, D4URxEvent, UserDetails } from 'src/utils/classes';
import { API_URL } from '../utils/api_url';

// const baseURL = 'https://api.fordshowtracker.com/';
const baseURL = API_URL;

const doFetch = async (url: RequestInfo, options: RequestInit, skipResults: boolean = false): Promise<D4URxFetchResult> => {
  // console.log('[d4ur]', 'doFetch', url);

  // add standard header stuff
  options.headers = {
    'Content-Type': 'application/json',
    'Authorization': stores.login.userToken,
    'latitude-app-version': `web:${stores.ui.appVersion}`,
    ...options.headers,
  };

  const response = await window.fetch(url, options);
  let result: object;
  try {
    // .tojson() failing on most responses from d4ur api
    try {
      result = await response.json();
    } catch (e: unknown) {
      console.error(`[doFetch]`, e);
      result = response;
    }
  } catch (e: unknown) {
    console.error(`[doFetch]`, e);
    result = {};
  }

  // console.log(`response ${url}`, {
  //   status: response.status,
  //   statusText: response.statusText,
  //   data: result,
  //   headers: response.headers,
  // });
  return { response, result };
}

const doMultipart = async (url: RequestInfo, options: RequestInit, skipResults: boolean = false): Promise<D4URxFetchResult> => {
  // console.log('[d4ur]', 'doMultipart', url);

  // add standard header stuff
  options.headers = {
    'Authorization': stores.login.userToken,
    'latitude-app-version': `web:${stores.ui.appVersion}`,
    ...options.headers,
  };
  console.log('RequestInit!!!!!!', options);

  const response = await window.fetch(url, options);
  let result: object;
  try {
    result = await response.json();
  } catch (e: unknown) {
    console.error(`[doMultipart]`, e);
    result = {};
  }

  // console.log(`response ${url}`, {
  //   status: response.status,
  //   statusText: response.statusText,
  //   data: result,
  //   headers: response.headers,
  // });
  return { response, result };
}

class D4URxService implements IService {
  init = async () => {
    console.log(`[FordD4URAPI] init`);
  }

  doLogin = async (email: string, password: string): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] doLogin`);

    try {
      stores.ui.setIsLoggingIn(true);

      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({ email, password }),
      };

      const ret = await doFetch(`${baseURL}v1/auth/signinD4UR`, requestOptions);
      setTimeout(() => {
        stores.ui.setIsLoggingIn(false);
      }, 1000);
      return ret;
    } catch (e) {
      // handle error
      console.error('doLogin', e);
      stores.ui.setIsLoggingIn(false);
      console.error(`[FordD4URAPI] doLogin`, e);
      return e;
    }
  }

  doForgotPassword = async (contact_email: string): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] doForgotPassword`);

    try {
      stores.ui.setIsResettingPassword(true);
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({
          contact_email,
        }),
      };

      const ret = await doFetch(`${baseURL}v1/auth/verify_email`, requestOptions);
      setTimeout(() => {
        stores.ui.setIsResettingPassword(false);
      }, 1000);
      return ret;
    } catch (e) {
      // handle error
      console.error('doForgotPassword', e);
      stores.ui.setIsResettingPassword(false);
      console.error(`[FordD4URAPI] doForgotPassword error`, e);
      return e;
    }
  }

  doResetPassword = async (password: string, token: string): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] doResetPassword`);

    try {
      stores.ui.setIsResettingPassword(true);
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({
          password,
        }),
        headers: { token }
      };

      const ret = await doFetch(`${baseURL}v1/auth/change_password`, requestOptions);
      setTimeout(() => {
        stores.ui.setIsResettingPassword(false);
      }, 1000);
      return ret;
    } catch (e) {
      // handle error
      console.error('doForgotPassword', e);
      stores.ui.setIsResettingPassword(false);
      console.error(`[FordD4URAPI] doResetPassword error`, e);
      return e;
    }
  }

  doLogout = async (): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] doLogout`);

    try {
      stores.ui.setIsLoggingOut(true);
      const requestOptions: RequestInit = {
        method: 'POST',
      };

      await doFetch(`${baseURL}logout`, requestOptions);

      stores.login.doLogout();
      stores.ui.setIsLoggingOut(false);
      return;
    } catch (e) {
      // handle error
      console.error('doLogout', e);
      console.error(`[FordD4URAPI] doLogout error`, e);
      return e;
    }
  }

  getEvents = async (seasonID: number): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] getEvents`);

    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };

      // TODO: eventually let them pick seasons
      const ret = await doFetch(`${baseURL}v1/event/allD4UR?season_id=${seasonID}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('getEvents', e);
      console.error(`[FordD4URAPI] getEvents`, e);
      return e;
    }
  }

  getRRDData = async (seasonID: number): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] getRRDData`);

    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };

      const ret = await doFetch(`${baseURL}v1/event/kitDelivery?season_id=${seasonID}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('getRRDData', e);
      console.error(`[FordD4URAPI] getRRDData`, e);
      return e;
    }
  }

  updateTracking = async (event_id: number, tracking_number: string): Promise<D4URxFetchResult | unknown | undefined> => {
    // for kit tracking, not checks
    console.log(`[FordD4URAPI] updateTracking`);

    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({ event_id, tracking_number }),
      };

      const ret = await doFetch(`${baseURL}v1/event/update-tracking-number`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('updateTracking', e);
      console.error(`[FordD4URAPI] updateTracking`, e);
      return e;
    }
  }

  updateShipDate = async (event_id: number, ship_date: string): Promise<D4URxFetchResult | unknown | undefined> => {
    // for kit tracking, not checks
    console.log(`[FordD4URAPI] updateShipDate`);

    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({ event_id, ship_date }),
      };

      const ret = await doFetch(`${baseURL}v1/event/update-ship-date`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('updateShipDate', e);
      console.error(`[FordD4URAPI] updateShipDate`, e);
      return e;
    }
  }

  setBlueCruiseStatus = async (event_id: number, is_blue_cruise: boolean): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] setBlueCruiseStatus`);

    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({ event_id, is_blue_cruise }),
      };

      const ret = await doFetch(`${baseURL}v1/event/set-blue-cruise`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('setBlueCruiseStatus', e);
      console.error(`[FordD4URAPI] setBlueCruiseStatus`, e);
      return e;
    }
  }

  getCheckQueue = async (seasonID: number): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] getCheckQueue`);

    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };

      // TODO: eventually let them pick seasons
      const ret = await doFetch(`${baseURL}v1/event/check_queue?season_id=${seasonID}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('getCheckQueue', e);
      console.error(`[FordD4URAPI] getCheckQueue`, e);
      return e;
    }
  }

  getEventRequest = async (event_request_id: string): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] getEventRequest`);

    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };

      // TODO: eventually let them pick seasons
      const ret = await doFetch(`${baseURL}v1/event/request/${event_request_id}`, requestOptions);
      console.log('event request ret :', ret);
      return ret;
    } catch (e) {
      // handle error
      console.error('getEventRequest', e);
      console.error(`[FordD4URAPI] getEventRequest`, e);
      return e;
    }
  }

  getEvent = async (event_id: string): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] getEvent`);

    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };

      // TODO: eventually let them pick seasons
      const ret = await doFetch(`${baseURL}v1/event/detail/${event_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('getEvent', e);
      console.error(`[FordD4URAPI] getEvent`, e);
      return e;
    }
  }

  getEventToken = async (event_id: string): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] getEventToken`);

    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({ event_id }),
      };

      const ret = await doFetch(`${baseURL}v1/auth/create_login_token`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('getEventToken', e);
      console.error(`[FordD4URAPI] getEventToken`, e);
      return e;
    }
  }

  getUsers = async (): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] getUsers`);

    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };

      const ret = await doFetch(`${baseURL}v1/user/users`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('getUsers', e);
      console.error(`[FordD4URAPI] getUsers`, e);
      return e;
    }
  }

  getUser = async (user_id: string): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] getUser`);

    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };

      const ret = await doFetch(`${baseURL}v1/user/user/${user_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('getUser', e);
      console.error(`[FordD4URAPI] getUser`, e);
      return e;
    }
  }

  createUser = async (user: UserDetails): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] createUser`);

    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({ user }),
      };

      const ret = await doFetch(`${baseURL}v1/user/user/`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('createUser', e);
      console.error(`[FordD4URAPI] createUser`, e);
      return e;
    }
  }

  updateUser = async (user: UserDetails): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] updateUser`);

    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({ user }),
      };

      const ret = await doFetch(`${baseURL}v1/user/user/${user.user_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('updateUser', e);
      console.error(`[FordD4URAPI] updateUser`, e);
      return e;
    }
  }

  getDealers = async (): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] getDealers`);

    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };

      const ret = await doFetch(`${baseURL}v1/event/dealers`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('getDealers', e);
      console.error(`[FordD4URAPI] getDealers`, e);
      return e;
    }
  }

  getDealer = async (dealer_id: string): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] getDealer`);

    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };

      const ret = await doFetch(`${baseURL}v1/event/dealer/${dealer_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('getDealer', e);
      console.error(`[FordD4URAPI] getDealer`, e);
      return e;
    }
  }

  updateDealer = async (dealer: Dealer): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] updateDealer`);

    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({ dealer }),
      };

      const ret = await doFetch(`${baseURL}v1/event/dealer/update/${dealer.dealer_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('updateDealer', e);
      console.error(`[FordD4URAPI] updateDealer`, e);
      return e;
    }
  }

  inviteOffWaitlist = async (dealer_id: number, invited_off: boolean): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] inviteOffWaitlist`);

    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({ dealer_id, invited_off }),
      };

      const ret = await doFetch(`${baseURL}v1/event/invite-off-waitlist/${dealer_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('inviteOffWaitlist', e);
      console.error(`[FordD4URAPI] inviteOffWaitlist`, e);
      return e;
    }
  }

  getWaitlistData = async (): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] getWaitlistData`);

    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };

      const ret = await doFetch(`${baseURL}v1/event/waitlist-data`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('getWaitlistData', e);
      console.error(`[FordD4URAPI] getWaitlistData`, e);
      return e;
    }
  }

  updateCharity = async (charity: Charity): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] updateCharity`);

    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({ charity }),
      };

      const ret = await doFetch(`${baseURL}v1/event/charity/${charity.charity_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('updateCharity', e);
      console.error(`[FordD4URAPI] updateCharity`, e);
      return e;
    }
  }

  updateEvent = async (event: D4URxEvent): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] updateEvent`);

    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({ event }),
      };

      const ret = await doFetch(`${baseURL}v1/event/update/${event.event_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('updateEvent', e);
      console.error(`[FordD4URAPI] updateEvent`, e);
      return e;
    }
  }

  updateEligibility = async (event_request: D4URxEvent): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] updateEligibility`, JSON.parse(event_request.json));

    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({ event_request: JSON.parse(event_request.json) }),
      };

      const ret = await doFetch(`${baseURL}v1/event/request/update/${event_request.event_request_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('updateEligibility', e);
      console.error(`[FordD4URAPI] updateEligibility`, e);
      return e;
    }
  }

  setEligibleEventRequest = async (event_request_id: number, assigned_planner: number): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] setEligibleEventRequest`);

    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({ assigned_planner }),
      };

      // TODO: eventually let them pick seasons
      const ret = await doFetch(`${baseURL}v1/event/request/eligible/${event_request_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('setEligibleEventRequest', e);
      console.error(`[FordD4URAPI] setEligibleEventRequest`, e);
      return e;
    }
  }

  setIneligibleEventRequest = async (event_request_id: number): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] setIneligibleEventRequest`);

    try {
      const requestOptions: RequestInit = {
        method: 'POST',
      };

      // TODO: eventually let them pick seasons
      const ret = await doFetch(`${baseURL}v1/event/request/ineligible/${event_request_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('setIneligibleEventRequest', e);
      console.error(`[FordD4URAPI] setIneligibleEventRequest`, e);
      return e;
    }
  }

  rejectPlan = async (event_request_id: number, message: string): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] rejectPlan`);

    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({ message }),
      };

      const ret = await doFetch(`${baseURL}v1/event/event_request/${event_request_id}/reject`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('rejectPlan', e);
      console.error(`[FordD4URAPI] rejectPlan`, e);
      return e;
    }
  }

  denyPlan = async (event_request_id: number, message: string): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] denyPlan`);

    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({ message }),
      };

      // TODO: eventually let them pick seasons
      const ret = await doFetch(`${baseURL}v1/event/request/deny/${event_request_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('denyPlan', e);
      console.error(`[FordD4URAPI] denyPlan`, e);
      return e;
    }
  }

  approvePlan = async (event_request_id: number): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] approvePlan`);

    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({ message: 'Event Approved' }),
      };

      // TODO: eventually let them pick seasons
      const ret = await doFetch(`${baseURL}v1/event/request/approve/${event_request_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('approvePlan', e);
      console.error(`[FordD4URAPI] approvePlan`, e);
      return e;
    }
  }

  getPlanners = async (): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] getPlanners`);

    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };

      // TODO: eventually let them pick seasons
      const ret = await doFetch(`${baseURL}v1/event/planners`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('getPlanners', e);
      console.error(`[FordD4URAPI] getPlanners`, e);
      return e;
    }
  }

  saveEventPlan = async (eventPlan: D4URxEvent, rawFile: any): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] saveEventPlan`);

    delete eventPlan.edit_log;

    const formData = new FormData();
    // set raw file data first
    rawFile && formData.append('event_plan_file', rawFile);

    // loop properties and add to form data
    const rawObject = JSON.parse(eventPlan.json);
    for (const name in rawObject) {
      rawObject[name] && formData.append(name, rawObject[name]);
    }

    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: formData,
      };

      const ret = await doMultipart(`${baseURL}v1/event/event_request/${eventPlan.event_request_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('saveEventPlan', e);
      stores.ui.setIsLoggingIn(false);
      console.error(`[FordD4URAPI] saveEventPlan`, e);
      return e;
    }
  }

  submitFinalApproval = async (event_request_id: any): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] submitFinalApproval`);

    try {
      const requestOptions: RequestInit = {
        method: 'POST',
      };

      const ret = await doFetch(`${baseURL}v1/event/event_request/${event_request_id}/approve`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('submitFinalApproval', e);
      stores.ui.setIsLoggingIn(false);
      console.error(`[FordD4URAPI] submitFinalApproval`, e);
      return e;
    }
  }

  submitDonationApproval = async (event_id: any, event_summary: string, check_amount: string, valid_post_count: number, bonus_valid_count: number): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] submitDonationApproval`);
    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({ event_summary, check_amount, valid_post_count, bonus_valid_count }),
      };

      const ret = await doFetch(`${baseURL}v1/checks/donation_approval/${event_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('submitDonationApproval', e);
      stores.ui.setIsLoggingIn(false);
      console.error(`[FordD4URAPI] submitDonationApproval`, e);
      return e;
    }
  }

  submitForcedDonationApproval = async (event_id: any, event_summary: string, check_amount: string, valid_post_count: number, bonus_valid_count: number): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] submitForcedDonationApproval`);
    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({ event_summary, check_amount, valid_post_count, bonus_valid_count }),
      };

      const ret = await doFetch(`${baseURL}v1/checks/forced_address_donation_approval/${event_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('submitForcedDonationApproval', e);
      stores.ui.setIsLoggingIn(false);
      console.error(`[FordD4URAPI] submitForcedDonationApproval`, e);
      return e;
    }
  }

  submitReimbursementApproval = async (event_id: any, amount: number): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] submitReimbursementApproval`);
    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({ amount }),
      };

      const ret = await doFetch(`${baseURL}v1/event/reimbursement_approval/${event_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('submitReimbursementApproval', e);
      stores.ui.setIsLoggingIn(false);
      console.error(`[FordD4URAPI] submitReimbursementApproval`, e);
      return e;
    }
  }

  submitForcedReimbursementApproval = async (event_id: any, amount: number): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] submitForcedReimbursementApproval`);
    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({ amount }),
      };

      const ret = await doFetch(`${baseURL}v1/event/forced_address_reimbursement_approval/${event_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('submitForcedReimbursementApproval', e);
      stores.ui.setIsLoggingIn(false);
      console.error(`[FordD4URAPI] submitForcedReimbursementApproval`, e);
      return e;
    }
  }

  submitCheckApproval = async (check_queue_id: any): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] submitCheckApproval`);
    try {
      const requestOptions: RequestInit = {
        method: 'get',
      };

      const ret = await doFetch(`${baseURL}v1/checks/check_approval/${check_queue_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('submitCheckApproval', e);
      stores.ui.setIsLoggingIn(false);
      console.error(`[FordD4URAPI] submitCheckApproval`, e);
      return e;
    }
  }

  resendCheckEmail = async (check_queue_id: any): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] resendCheckEmail`);
    try {
      const requestOptions: RequestInit = {
        method: 'post',
      };

      const ret = await doFetch(`${baseURL}v1/checks/resend_email/${check_queue_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('resendCheckEmail', e);
      stores.ui.setIsLoggingIn(false);
      console.error(`[FordD4URAPI] resendCheckEmail`, e);
      return e;
    }
  }

  // TODO: make checkExceptionData real obj
  requestCheckException = async (queuedCheck: any): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] requestCheckException`);

    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify(queuedCheck),
      };

      const ret = await doFetch(`${baseURL}v1/event/check_exception_request`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('requestCheckException', e);
      console.error(`[FordD4URAPI] requestCheckException`, e);
      return e;
    }
  }

  // TODO: make checkExceptionData real obj
  approveCheckException = async (check_queue_id: any): Promise<D4URxFetchResult | unknown | undefined> => {
    console.log(`[FordD4URAPI] approveCheckException`);

    try {
      const requestOptions: RequestInit = {
        method: 'GET',
      };

      const ret = await doFetch(`${baseURL}v1/event/check_exceptions/${check_queue_id}`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('approveCheckException', e);
      console.error(`[FordD4URAPI] approveCheckException`, e);
      return e;
    }
  }

  triggerEmail = async (emailType: string, event_id: number, recipients: string = ''): Promise<D4URxFetchResult | unknown | undefined> => {
    try {
      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({
          email_type: emailType,
          event_id,
          recipients,
        }),
      };

      const ret = await doFetch(`${baseURL}v1/event/send-email-admin`, requestOptions);
      return ret;
    } catch (e) {
      // handle error
      console.error('requestCheckException', e);
      console.error(`[FordD4URAPI] requestCheckException`, e);
      return e;
    }
  }
}

export default new D4URxService();
