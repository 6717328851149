import { observer } from "mobx-react-lite";
import { useLocalization } from "@progress/kendo-react-intl";
import { useStores } from "src/stores";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { useEffect, useState, useRef } from "react";
import { useServices } from "src/services";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridToolbar
} from "@progress/kendo-react-grid";
import { Button, ToolbarSpacer } from "@progress/kendo-react-buttons";
import { useHistory } from "react-router";
import { DataResult, process, State } from "@progress/kendo-data-query";
import { D4URxEvent } from "src/utils/classes";
import {
  ColumnMenu,
} from "src/components/GridColumnMenus";
import multiColumnSort from "multi-column-sort";
import moment from 'moment-timezone';
import { DatePicker } from 'src/components/Form/DatePicker';

const KitDeliveryScreen = () => {
  const localizationService = useLocalization();

  const _export = useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const { ui, login } = useStores();
  const { d4ur } = useServices();
  let history = useHistory();

  const sortPresets: { [key: string]: State; } = {
    clear: {
      sort: [
        {
          field: 'sort_date',
          dir: 'asc',
        },
      ]
    },
    default: {
      filter: {
        logic: "or", filters: [
          {
            field: "kit_tracking_number",
            operator: "eq",
            value: ''
          },
          {
            field: "kit_tracking_number",
            operator: "isnull",
          }
        ]
      },
      sort: [
        {
          field: 'start_date',
          dir: 'asc',
        },
      ]
    },
  }

  const [dataState, setDataState] = useState<State>(sortPresets.default);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');

  type Direction = "ASC" | "DESC";
  type SortObject<T> = {
    [key in keyof T]?: Direction;
  };

  const customProcess = (data: any[], state: State) => {
    const preProcessed = process(data, state).data;
    // custom sort
    const getColumnValues = (column: any, value: any) => {
      switch (column) {
        // case 'sort_status':
        //     return value.data.value;
        default:
          return value;
      }
    }

    const sortOrder: SortObject<any> = {};

    for (const s of (state.sort || [])) {
      let fieldToSort = s.field;

      switch (fieldToSort) {
        case 'status_pretty':
          fieldToSort = 'sort_status';
          break;
      }

      sortOrder[fieldToSort] = s.dir?.toUpperCase() as Direction;
    };

    let newData = [];
    if (state.sort?.length) {
      newData = multiColumnSort(
        preProcessed,
        sortOrder,
        getColumnValues
      );
    } else {
      newData = preProcessed;
    }

    return {
      data: newData,
      total: preProcessed.length,
    } as DataResult;
  }


  const processEvents = (events: D4URxEvent[]): D4URxEvent[] => {
    return events.map((e) => {
      return new D4URxEvent(e);
    });
  };

  // const [planners, setPlanners] = useState<UserDetails[]>([]);
  const [eventList, setEventList] = useState<D4URxEvent[]>([]);
  const [dataResult, setDataResult] = useState<DataResult>(
    customProcess(eventList, dataState)
  );

  const loadEvents = () => {
    ui.setIsGettingEvents(true);
    d4ur.getRRDData(ui.currentSeason.season_id).then((ret) => {
      console.log('get rrd data ret :', ret);
      ui.setIsGettingEvents(false);

      const processedEvents = processEvents(
        (ret as D4URxFetchResult).result || []
      );

      // loop through processed events and move custom_data.kit_ship_date to the top level for excel export
      processedEvents.forEach((e) => {
        const { custom_data } = e;
        const kitShipDate = JSON.parse(custom_data || '').kit_ship_date || null;
        e.kit_ship_date = kitShipDate ? moment(kitShipDate).format('YYYY-MM-DD') : null;
      });

      setEventList(processedEvents);
      setDataResult(customProcess(processedEvents, dataState));
      // const rdaTemp = processedEvents.filter((e) => e.event_id && !e.cancelled);
    });
  }

  useEffect(() => {
    loadEvents();
  }, []);

  const trackingNumberCell = (props: GridCellProps) => {
    const { event_id, kit_tracking_number } = new D4URxEvent(props.dataItem);
    console.log('kit_tracking_number :', typeof kit_tracking_number, kit_tracking_number);

    const [trackingNumber, setTrackingNumber] = useState<string>(kit_tracking_number ? kit_tracking_number : '');

    return (
      <td style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center' }}>
        <input
          placeholder="Tracking #"
          value={trackingNumber}
          onChange={(e) => setTrackingNumber(e.target.value)}
        />
        <Button
          className="k-button k-primary"
          style={{ marginTop: '5px' }}
          onClick={async () => {
            if (event_id) {
              try {
                await d4ur.updateTracking(event_id, trackingNumber);
                loadEvents();
                setSuccessMessage('Tracking number saved successfully.');
                setTimeout(() => {
                  setSuccessMessage('');
                }, 2500);
              } catch (error: any) {
                alert(`There was an error saving the tracking number (${error.message ? error.message : 'unknown error'})`);
              }
            }
          }}
        >
          Save
        </Button>
        {kit_tracking_number && <Button
          className="k-button k-primary"
          style={{ marginTop: '5px' }}
          onClick={async () => {
            window.open(`https://www.ups.com/track?tracknum=${kit_tracking_number}`, '_blank');
          }}
        >
          Go to Tracking
        </Button>}
      </td>
    );
  };

  const shipDateCell = (props: GridCellProps) => {
    const { event_id, kit_tracking_number, custom_data } = new D4URxEvent(props.dataItem);

    const kitShipDate = JSON.parse(custom_data || '').kit_ship_date || null;
    console.log('kitShipDate :', kitShipDate);
    if (kit_tracking_number) {
      return (
        <td >
          <DatePicker
            value={kitShipDate ? new Date(kitShipDate) : null}
            onChange={async (e: any) => {
              if (event_id) {
                try {
                  await d4ur.updateShipDate(event_id, e.value);
                  loadEvents();
                  setSuccessMessage('Ship date saved successfully.');
                  setTimeout(() => {
                    setSuccessMessage('');
                  }, 2500);
                } catch (error: any) {
                  alert(`There was an error saving the ship date (${error.message ? error.message : 'unknown error'})`);
                }
              }
            }}
          />
        </td>
      )
    }
    return (
      <td >
        <p>Not Shipped</p>
      </td>
    )
  };

  const resendEmailCell = (props: GridCellProps) => {
    const { event_id, kit_tracking_number, custom_data } = new D4URxEvent(props.dataItem);
    const kitEmailData = JSON.parse(custom_data || '').kit_delivery_email || []
    const getLastSentAt = (emailsSent: Array<emailsSent>) => {
      console.log('emailsSent :', emailsSent);
      if (!emailsSent || !emailsSent.length) return 'Not Yet Sent';
      const parseDate = (email: emailsSent) => new Date(email.sent_at);
      emailsSent.sort((a, b) => parseDate(b).getTime() - parseDate(a).getTime());
      const mostRecentEmail = emailsSent[0];
      // return `${moment(mostRecentEmail.sent_at).tz('America/New_York').format('MMMM Do, h:mm a')} (EST) to ${mostRecentEmail.sent_to.length > 1 ? mostRecentEmail.sent_to.join(', ') : mostRecentEmail.sent_to}`;
      return `Last Sent: ${moment(mostRecentEmail.sent_at).tz('America/New_York').format('MMMM Do, h:mm a')} (EST)`;
    }
    return (
      <td>
        <Button
          className="k-button k-primary"
          style={{ marginTop: '5px' }}
          onClick={async () => {
            if (event_id) {
              try {
                await d4ur.triggerEmail('kit_delivery', event_id, '');
                loadEvents();
                setSuccessMessage('Email sent successfully.');
                setTimeout(() => {
                  setSuccessMessage('');
                }, 2500);
              } catch (error: any) {
                alert(`There was an error sending the email (${error.message ? error.message : 'unknown error'})`);
              }
            }
          }}
          disabled={!kit_tracking_number}
        >
          Re-Send Dealer Kit Tracking Email
        </Button>
        {kit_tracking_number ? <p>{getLastSentAt(kitEmailData)}</p> : null}
      </td>
    )
  };

  const doDataState = (state: State) => {
    console.log('event.dataState', state);
    setDataResult(customProcess(eventList, state));
    setDataState(state);
  }

  return (
    <div id="Dashboard" className="dashboard-page main-content">
      <div className="card-container grid">
        <div>
          <p>User: {login.user?.credentials.display_name}</p>
          <h3 className="card-title">
            {localizationService.toLanguageString("custom.kit_delivery", "RRD Kit Delivery Information")}
          </h3>
          {successMessage ? <p style={{ color: 'green' }}>{successMessage}</p> : null}
        </div>
        {/* // TODO: going to replace "sending..." with real spinner during styling updates. same on Event page.  */}
        {isLoading ? <p style={{ color: 'red' }}>Sending...</p> : null}
        {errorMessage ? <p style={{ color: 'red' }}>{errorMessage}</p> : null}
        <div className="card-component">
          <ExcelExport data={dataResult.data} ref={_export} fileName={`rrd_kit_delivery_export_${moment().format('YYYYMMDD-hhmma')}.xlsx`}>
            <ExcelExportColumn field="event_id" title="Latitude Event ID" />
            <ExcelExportColumn field="start_date" title="Event Date" />
            <ExcelExportColumn field="shipping_location" title="Shipping Location" />
            <ExcelExportColumn field="dealer_name" title="Dealer" />
            <ExcelExportColumn field="dealer_contact_name" title="Dealer Contact" />
            <ExcelExportColumn field="dealer_contact_email" title="Dealer Contact Email" />
            <ExcelExportColumn field="dealer_contact_phone" title="Dealer Contact Phone" />
            <ExcelExportColumn field="address" title="Delivery Address" />
            <ExcelExportColumn field="address_2" title="Address 2" />
            <ExcelExportColumn field="city" title="City" />
            <ExcelExportColumn field="state" title="State" />
            <ExcelExportColumn field="zip_code" title="Zip Code" />
            <ExcelExportColumn field="attn_contact_name" title="Attn. Contact Name" />
            <ExcelExportColumn field="attn_contact_email" title="Attn. Contact Email" />
            <ExcelExportColumn field="attn_contact_phone" title="Attn. Contact Phone" />
            <ExcelExportColumn field="kit_tracking_number" title="Tracking Number" />
            <ExcelExportColumn field="kit_ship_date" title="Ship Date" />
          </ExcelExport>
          <Grid
            data={dataResult}
            sortable={{ mode: "single" }}
            {...dataState}
            onDataStateChange={(event: GridDataStateChangeEvent) => {
              console.log("event.dataState", event.dataState);
              setDataResult(customProcess(eventList, event.dataState));
              setDataState(event.dataState);
            }}
            // scrollable="none"
            resizable={true}
          // size={"medium"}
          // style={{
          //     height: "450px",
          //   }}
          >
            <GridToolbar>
              {/* <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.default)}>Default</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.action)}>Action Needed</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.waiting)}>Waiting on Dealer</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.today)}>Today's Events</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.tomorrow)}>Tomorrow</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.thisWeek)}>This Week</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.nextWeek)}>Next Week</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.yesterday)}>Yesterday</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.lastWeek)}>Last Week</button> */}
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.default)}>Default</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={() => doDataState(sortPresets.clear)}>Show All Events</button>
              <ToolbarSpacer />
              <button className="k-button k-button-md k-rounded-md k-button-solid" onClick={excelExport}>Export Current View</button>
            </GridToolbar>
            {/* <GridColumn
            field="event_name"
            title={localizationService.toLanguageString(
                "custom.event_name",
                "Event Name"
            )}
            columnMenu={ColumnMenu}
            /> */}
            <GridColumn
              field="dealer_name"
              title={localizationService.toLanguageString(
                "custom.dealer",
                "dealer"
              )}
              columnMenu={ColumnMenu}
              width={100}
            />
            <GridColumn
              field="start_date"
              title="Event Date"
              format="{0:E, MMM dd yyyy}"
              filter="date"
              columnMenu={ColumnMenu}
              width={150}
            />
            <GridColumn
              field="shipping_location"
              title={localizationService.toLanguageString(
                "custom.shipping_location",
                "Shipping Location"
              )}
              columnMenu={ColumnMenu}
              width={200}
            />
            {/* <GridColumn
              field="dealer_contact_name"
              title={localizationService.toLanguageString(
                "custom.dealer_contact",
                "Dealer Contact Name"
              )}
              columnMenu={ColumnMenu}
              cell={DealerNameCell}
            />
            <GridColumn
              field="dealer_contact_email"
              title={localizationService.toLanguageString(
                "custom.dealer_contact",
                "Dealer Contact Email"
              )}
              columnMenu={ColumnMenu}
              cell={DealerNameCell}
            />
            <GridColumn
              field="dealer_contact_phone"
              title={localizationService.toLanguageString(
                  "custom.dealer_contact",
                  "Dealer Contact Phone"
                )}
                columnMenu={ColumnMenu}
                cell={DealerNameCell}
                />
                <GridColumn
                field="charity_partner"
                title="Organization"
                columnMenu={ColumnMenu}
            /> */}
            <GridColumn
              field="attn_contact_name"
              title="Attn. Name"
              columnMenu={ColumnMenu}
              width={150}
            />
            <GridColumn
              field="address"
              title="Address"
              columnMenu={ColumnMenu}
              width={150}
            />
            <GridColumn
              field="address_2"
              title="Address 2"
              columnMenu={ColumnMenu}
              width={150}
            />
            <GridColumn
              field="city"
              title="City"
              columnMenu={ColumnMenu}
            />
            <GridColumn
              field="state"
              title="State"
              columnMenu={ColumnMenu}
              width={100}
            />
            <GridColumn
              field="zip_code"
              title="Zip Code"
              columnMenu={ColumnMenu}
              width={150}
            />
            <GridColumn title='Ship Date' cell={shipDateCell} width={200} />
            <GridColumn title='Tracking #' cell={trackingNumberCell} width={200} />
            <GridColumn title='Resend Email' cell={resendEmailCell} width={300} />
            {/* <GridColumn
              field="attn_contact_email"
              title="Attn. Email"
              columnMenu={ColumnMenu}
            />
            <GridColumn
              field="attn_contact_phone"
              title="Attn. Phone"
              columnMenu={ColumnMenu}
            /> */}

          </Grid>
        </div>
      </div>
    </div>
  );
};

export default observer(KitDeliveryScreen);
